<template>
  <v-container>
    <v-card-title class="justify-center this-header">
      {{ header }}
    </v-card-title>
    <v-subheader class="justify-center height-24">
      {{ subheader }}
    </v-subheader>
  </v-container>
</template>

<script>
export default {
  name: "PageHeader",

  props: {
    header: String,
    subheader: String
  }
};
</script>

<style scoped>
.this-header {
  padding: 0;
  margin-top: 10px;
}

.height-24 {
  height: 24px;
}
</style>
