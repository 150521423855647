<template>
  <v-container fluid class="main-container" style="text-align: center;">
    <PageHeader
      header="View Submissions"
      :subheader="activeService.name || 'No service selected'"
    />

    <v-select
      :items="itemFilters"
      v-model="submissionFilter"
      @change="filterChanged"
      class="mt-10"
      label="Filter submissions"
      style="margin: auto; max-width: 320px;"
    />

    <v-data-table :items-per-page="15" dense :headers="headers" :items="filteredItems" />
  </v-container>
</template>

<script>
import {
  FILTER_ALL,
  FILTER_COMPLETE,
  FILTER_INCOMPLETE,
  STAGE,
  consolidateMapSubmission,
  getActiveOffsiteService
} from "@/store/offsiteService";

import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "ViewSubmissions",

  components: {
    PageHeader
  },

  data: () => ({
    activeService: { id: null, name: null },
    headers: [
      { text: "Name", value: "name" },
      { text: "User Code", value: "userCode" },
      { text: STAGE.FECALYSIS, value: "fecalysis", align: "center" },
      { text: STAGE.URINALYSIS, value: "urinalysis", align: "center" }
    ],
    items: [],
    itemFilters: [FILTER_ALL, FILTER_COMPLETE, FILTER_INCOMPLETE],
    filteredItems: [],
    sampleCode: "",
    submissionFilter: FILTER_ALL
  }),

  mounted() {
    this.activeService = getActiveOffsiteService();

    consolidateMapSubmission(this.activeService.id).then(submissionMap => {
      for (const key in submissionMap) {
        const value = submissionMap[key];
        this.items.push({
          name: value["name"] || "",
          userCode: key || "",
          fecalysis: value[STAGE.FECALYSIS] ? "DONE" : "",
          urinalysis: value[STAGE.URINALYSIS] ? "DONE" : ""
        });
      }

      this.filteredItems = [...this.items];
    });
  },

  methods: {
    filterChanged() {
      if (FILTER_ALL === this.submissionFilter) {
        this.filteredItems = [...this.items];
      } else if (FILTER_COMPLETE === this.submissionFilter) {
        this.filteredItems = this.items.filter(
          doc => doc.fecalysis && doc.urinalysis
        );
      } else if (FILTER_INCOMPLETE === this.submissionFilter) {
        this.filteredItems = this.items.filter(
          doc => !(doc.fecalysis && doc.urinalysis)
        );
      }
    }
  }
};
</script>
