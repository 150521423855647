import { DB, initializeDBNames, setCurrentUser } from "@/common/store";
import { TRANSACTION_TYPE_WALKIN as WALKIN } from "@/common/utilities/appointments";

import { db, reloadPageOnRoleValidation } from "@/main";
import { getDateFromTimestamp } from "@/common/utilities/date";
import { GetShortTimeFromTimestamp } from "@/common/utilities/time";
import { setStaging } from "@/common/main";

import Vue from "vue";
import Vuex from "vuex";

setStaging(false);
initializeDBNames();

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { vuexfireMutations } from "vuexfire";

Vue.use(Vuex);

const PROFILES = "profiles";

const OFFSITE_ADMIN = "admin";
export const OFFSITE_STAFF = "clinic-medtech";
const ROLES = [OFFSITE_ADMIN, OFFSITE_STAFF];

var emptyProfile = {
  loaded: false,

  displayName: "",
  roles: []
};

function GetSchedule(data) {
  let start = data.schedule && data.schedule.start ? data.schedule.start : "";
  let end = data.schedule && data.schedule.end ? data.schedule.end : "";

  if (start && end) {
    let startDate = getDateFromTimestamp(start);
    let startTime = GetShortTimeFromTimestamp(start);
    let endTime = GetShortTimeFromTimestamp(end);

    return ` (${startDate} ${startTime} - ${endTime})`;
  }

  return "";
}

function GetServices(snapshot, isWalkin) {
  let services = [];

  snapshot.forEach(doc => {
    let data = doc.data();

    if (isWalkin) {
      if (WALKIN === data.name && data.schedule && data.schedule.start) {
        services.push({
          id: doc.id,
          name: GetSchedule(data),
          schedule: data.schedule.start
        });
      }
    } else if (data.schedule && data.schedule.start) {
      services.push({
        id: doc.id,
        name: data.name + GetSchedule(data),
        schedule: data.schedule.start
      });
    }
  });

  services.sort((a, b) => {
    return b.schedule - a.schedule;
  });

  return services;
}

export function LoadUserAppointment(payload) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_ATTENDEE)
      .doc(payload.userserviceid)
      .get()
      .then(doc => {
        let appointmentlst = [];
        if (doc.exists) {
          appointmentlst.push({
            id: doc.id,
            data: doc.data()
          });
        }
        resolve(appointmentlst);
      })
      .catch(error => reject(error));
  });
}

export default new Vuex.Store({
  state: {
    auth: {
      loggedIn: false,
      uid: "",
      roles: []
    },

    profile: { ...emptyProfile },
    services: {},

    activeApeDate: null,
    activeUserCode: "",
    activeUserInfo: null
  },

  getters: {
    getActiveApeDate: state => {
      return state.activeApeDate;
    },

    getActiveUserCode: state => {
      return state.activeUserCode;
    },

    getActiveUserInfo: state => {
      return state.activeUserInfo;
    },

    isAdmin: state => {
      return state.auth.roles.includes(OFFSITE_ADMIN);
    },

    isAllowed: state => {
      return ROLES.some(r => state.auth.roles.includes(r));
    }
  },

  mutations: {
    ...vuexfireMutations,

    //---------------------------- AUTH MUTATIONS ----------------------------//

    addRole({ auth }, role) {
      auth.roles.push(role);
    },

    setUser({ auth, profile }, user) {
      if (user) {
        setCurrentUser(user.uid, user.displayName);

        auth.uid = user.uid;

        this.dispatch("getProfile", user);

        auth.loggedIn = true;
      } else {
        setCurrentUser("", "");

        auth.loggedIn = false;
        auth.uid = "";
        for (let key in emptyProfile) {
          profile[key] = emptyProfile[key];
        }
      }
    },

    setProfileData({ profile }, data) {
      for (let key in data) {
        profile[key] = data[key];
      }

      profile.loaded = true;
    },

    //-------------------------- SERVICES MUTATIONS --------------------------//

    setServices(state, data) {
      state.services = {};
      for (const entry of data) {
        state.services[entry.id] = { ...entry };
      }
    },

    SET_ACTIVE_APE_DATE(state, data) {
      state.activeApeDate = data;
    },

    SET_ACTIVE_USER_CODE(state, data) {
      state.activeUserCode = data;
    },

    SET_ACTIVE_USER_INFO(state, data) {
      state.activeUserInfo = { ...data };
    }
  },

  actions: {
    //----------------------- OFFSITE_SERVICES ACTIONS -----------------------//

    getServices({ commit }, isWalkin) {
      return new Promise((resolve, reject) => {
        db.collection(DB.OFFSITE_SERVICES)
          .get()
          .then(snapshot => {
            const services = GetServices(snapshot, isWalkin);
            commit("setServices", services);
            resolve(services);
          })
          .catch(err => reject(err));
      });
    },

    //---------------------------- OTHER ACTIONS ----------------------------//

    getProfile({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.profile.loaded || state.auth.uid === "") {
          resolve(state.profile);
        }

        db.collection(PROFILES)
          .doc(state.auth.uid)
          .get()
          .then(doc => {
            let data = {};
            if (doc.exists) {
              data = doc.data();
            }

            if (data.displayName == null) {
              let user = firebase.auth().currentUser;
              if (user) {
                data.displayName = user.displayName;

                if (data.displayName) {
                  doc.ref.update({
                    displayName: data.displayName
                  });
                }
              }
            }

            if ("roles" in data) {
              data["roles"].forEach(value => {
                commit("addRole", value);
              });
            }

            commit("setProfileData", data);
            data.loaded = true;

            reloadPageOnRoleValidation();
            resolve();
          })
          .catch(err => reject(err));
      });
    },

    setActiveApeDate({ commit }, data) {
      return new Promise(resolve => {
        commit("SET_ACTIVE_APE_DATE", data);
        resolve();
      });
    },

    setActiveUserCode({ commit }, data) {
      return new Promise(resolve => {
        commit("SET_ACTIVE_USER_CODE", data);
        resolve();
      });
    },

    setActiveUserInfo({ commit }, data) {
      return new Promise(resolve => {
        commit("SET_ACTIVE_USER_INFO", data);
        resolve();
      });
    }
  },

  modules: {},

  strict: true
});

//-------------------------------- UTILITIES --------------------------------//

function getTodayAndTomorrowDate() {
  let today = new Date();
  today.setHours(0, 0, 0);

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  today.setHours(0, 0, 0);

  return { today: today, tomorrow: tomorrow };
}

//------------------------------ OFFSITE_SCHEDULES ------------------------------//

function normalizeServiceSchedule(data) {
  let attendees = [];

  if (data.attendees) {
    data.attendees.forEach(attendee => {
      attendees.push({ ...attendee });
    });
  }

  return {
    attendees: [...attendees],
    schedule: {
      end: new Date(data.schedule.end.seconds * 1000),
      start: new Date(data.schedule.start.seconds * 1000)
    },
    service: { ...data.service },
    slot: {
      end: new Date(data.slot.end.seconds * 1000),
      start: new Date(data.slot.start.seconds * 1000)
    },
    slots: data.slots,
    status: data.status
  };
}

function getSchedules(snapshot) {
  let schedules = [];

  snapshot.forEach(doc => {
    schedules.push({
      id: doc.id,
      ...normalizeServiceSchedule(doc.data())
    });
  });

  return schedules;
}

export function GetServiceSchedules(payload) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SCHEDULES)
      .where("service.id", "==", payload.serviceId)
      .where("schedule.start", ">=", new Date(payload.startDate + " 00:00:00"))
      .orderBy("schedule.start", "asc")
      .orderBy("slot.start", "asc")
      .get()
      .then(snapshot => resolve(getSchedules(snapshot)))
      .catch(err => reject(err));
  });
}

export function GetServiceSchedulesToday(serviceId) {
  return new Promise((resolve, reject) => {
    const dates = getTodayAndTomorrowDate();

    db.collection(DB.OFFSITE_SCHEDULES)
      .where("service.id", "==", serviceId)
      .where("schedule.start", ">=", dates.today)
      .where("schedule.start", "<", dates.tomorrow)
      .orderBy("schedule.start", "asc")
      .orderBy("slot.start", "asc")
      .get()
      .then(snapshot => resolve(getSchedules(snapshot)))
      .catch(err => reject(err));
  });
}

//----------------------------- OFFSITE_ATTENDEE -----------------------------//

export function AttendeesToday(serviceID) {
  return new Promise((resolve, reject) => {
    const dates = getTodayAndTomorrowDate();

    db.collection(DB.OFFSITE_ATTENDEE)
      .where("service.id", "==", serviceID)
      .where("schedule.start", ">=", new Date(dates.today))
      .where("schedule.start", "<=", new Date(dates.tomorrow))
      .orderBy("schedule.start", "asc")
      .limit(50)
      .get()
      .then(snapshot => {
        let clinics = [];
        snapshot.forEach(doc => {
          clinics.push({
            id: doc.id,
            data: doc.data()
          });
        });
        resolve(clinics);
      })
      .catch(err => reject(err));
  });
}
