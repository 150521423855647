import { DB, getCurrentUserUid } from "@/common/store";
import { STAGE } from "@/store/offsiteService";

import { db } from "@/main";

export function getBaseData(submissionType, scheduleId, userCode, value) {
  return {
    encoder: getCurrentUserUid(),
    scheduleId: scheduleId,
    timestamp: new Date(),
    type: submissionType,
    userCode: userCode,
    value: value
  };
}

export function addSubmission(submissionType, scheduleId, userCode, value) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .add({
        ...getBaseData(submissionType, scheduleId, userCode, value)
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function addDoctorAssessment(scheduleId, userCode, data) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .add({
        ...getBaseData(STAGE.DOCTOR, scheduleId, userCode, true),
        ...data
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function addTriage(scheduleId, userCode, data) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .add({
        ...getBaseData(STAGE.TRIAGE, scheduleId, userCode, true),
        ...data
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

function getData(scheduleId, userCode, stage) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .where("scheduleId", "==", scheduleId)
      .where("userCode", "==", userCode)
      .where("type", "==", stage)
      .orderBy("timestamp", "desc")
      .limit(1)
      .get()
      .then(snapshot => {
        let details = {};

        snapshot.forEach(doc => {
          details = { ...doc.data() };
        });

        resolve(details);
      })
      .catch(err => reject(err));
  });
}

export function getDoctorAssessment(scheduleId, userCode) {
  return getData(scheduleId, userCode, STAGE.DOCTOR);
}

function processSnapshot(snapshot) {
  let submissions = {
    [STAGE.CHECKIN]: false,
    [STAGE.TRIAGE]: false,
    [STAGE.BLOOD_EXTRACTION]: false,
    [STAGE.URINALYSIS]: false,
    [STAGE.FECALYSIS]: false,
    [STAGE.XRAY]: false,
    [STAGE.ECG]: false,
    [STAGE.PAP_SMEAR]: false,
    [STAGE.DOCTOR]: false,
    [STAGE.CHECKOUT]: false
  };

  snapshot.forEach(doc => {
    const data = doc.data();

    switch (data.type) {
      case STAGE.CHECKIN:
        submissions[STAGE.CHECKIN] = data.value || false;
        break;

      case STAGE.TRIAGE:
        submissions[STAGE.TRIAGE] = true;
        break;

      case STAGE.BLOOD_EXTRACTION:
        submissions[STAGE.BLOOD_EXTRACTION] = data.value || false;
        break;

      case STAGE.URINALYSIS:
        submissions[STAGE.URINALYSIS] = data.value || false;
        break;

      case STAGE.FECALYSIS:
        submissions[STAGE.FECALYSIS] = data.value || false;
        break;

      case STAGE.XRAY:
        submissions[STAGE.XRAY] = data.value || false;
        break;

      case STAGE.ECG:
        submissions[STAGE.ECG] = data.value || false;
        break;

      case STAGE.PAP_SMEAR:
        submissions[STAGE.PAP_SMEAR] = data.value || false;
        break;

      case STAGE.DOCTOR:
        submissions[STAGE.DOCTOR] = true;
        break;

      case STAGE.CHECKOUT:
        submissions[STAGE.CHECKOUT] = data.value || false;
        break;
    }
  });

  return submissions;
}

export function getScheduleData(scheduleId, userCode) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .where("scheduleId", "==", scheduleId)
      .where("userCode", "==", userCode)
      .orderBy("timestamp", "asc")
      .get()
      .then(snapshot => resolve(processSnapshot(snapshot)))
      .catch(err => reject(err));
  });
}

export function getTriage(scheduleId, userCode) {
  return getData(scheduleId, userCode, STAGE.TRIAGE);
}
