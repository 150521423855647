import { DATA, getLastSubmission } from "@/store/offsiteService";
import { DB } from "@/common/store";

import { db } from "@/main";

export const REGISTRANTS = "registrants";

function generateStructure(scheduleId, userCode, type, content) {
  return {
    ...content,

    scheduleId: scheduleId,
    userCode: userCode,
    type: type,
    timestamp: new Date()
  };
}

export function addPhoto(scheduleId, userCode, photo) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .add(
        generateStructure(scheduleId, userCode, DATA.PHOTO, { photo: photo })
      )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function addTemperature(scheduleId, userCode, temperature, name) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .add(
        generateStructure(scheduleId, userCode, DATA.TEMPERATURE, {
          temperature: temperature,
          name: name
        })
      )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function getAttendees(scheduleId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .where("scheduleId", "==", scheduleId)
      .get()
      .then(snapshot => {
        let attendees = { registrants: [] };

        snapshot.forEach(doc => {
          const data = doc.data();

          if (!(data.type in attendees)) {
            attendees[data.type] = [];
          }

          if (!attendees[data.type].includes(data.userCode)) {
            attendees[data.type].push(data.userCode);
          }

          if (!attendees[REGISTRANTS].includes(data.userCode)) {
            attendees[REGISTRANTS].push(data.userCode);
          }
        });

        resolve(attendees);
      })
      .catch(err => reject(err));
  });
}

function lastPhoto(snapshot) {
  let lastSubmission = null;
  let photo = "";

  snapshot.forEach(doc => {
    const data = doc.data();
    if (!lastSubmission || lastSubmission < data.timestamp) {
      lastSubmission = data.timestamp;
      photo = data.photo;
    }
  });

  return { photo: photo };
}

function lastTemperature(snapshot) {
  let lastSubmission = null;
  let temperature = "";

  snapshot.forEach(doc => {
    const data = doc.data();
    if (!lastSubmission || lastSubmission < data.timestamp) {
      lastSubmission = data.timestamp;
      temperature = data.temperature;
    }
  });

  return { temperature: temperature };
}

export function getPhoto(scheduleId, userCode) {
  return getLastSubmission(scheduleId, userCode, DATA.PHOTO, lastPhoto);
}

export function getTemperature(scheduleId, userCode) {
  return getLastSubmission(
    scheduleId,
    userCode,
    DATA.TEMPERATURE,
    lastTemperature
  );
}

export function getUserDetails(userCode) {
  return new Promise((resolve, reject) => {
    // index: 1
    db.collection(DB.OFFSITE_ATTENDEE)
      .where("user.code", "==", userCode)
      .orderBy("timestamp", "desc")
      .limit(1)
      .get()
      .then(snapshot => {
        let details = {};

        snapshot.forEach(doc => {
          let data = doc.data();
          details = {
            id: doc.id,
            age: data.user.age,
            birthday: data.user.birthday,
            firstname: data.user.firstName,
            gender: data.user.gender,
            lastName: data.user.lastName
          };
        });

        resolve(details);
      })
      .catch(err => reject(err));
  });
}
