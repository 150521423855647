<template>
  <v-app id="app">
    <AppBarMenu class="no-print" />

    <v-main>
      <v-container fluid>
        <BackButton
          v-if="isBackButtonVisible"
          class="no-print"
          style="position: fixed; z-index: 1;"
        />

        <router-view class="mt-5" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBarMenu from "@/components/AppBarMenu.vue";
import BackButton from "@/components/BackButton.vue";

export default {
  name: "App",

  components: {
    AppBarMenu,
    BackButton
  },

  computed: {
    isBackButtonVisible() {
      switch (this.$route.name) {
        case "Menu":
        case "Signin":
          return false;
      }

      return true;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-container {
  max-width: 1000px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  @page {
    size: auto;
    margin: 0mm;
    margin-top: -15mm;
  }
}
</style>
