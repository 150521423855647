<template>
  <v-card>
    <div class="pt-6 text-center">
      <strong>{{ header }}</strong>
    </div>

    <div class="mt-3 text-center">
      <span v-show="!lastSubmissionDate">No submissions</span>
      <span v-show="lastSubmissionDate">
        Last submission: {{ lastSubmissionDate }}
      </span>

      <div v-show="submissionUrl">
        <a :href="submissionUrl" target="_blank">Open in New Tab</a>
      </div>
    </div>

    <div class="mt-8 text-center">
      <input
        ref="file_upload_input"
        type="file"
        accept="application/pdf,image/*"
        @change="selectFile"
      />
    </div>

    <div class="pb-6 mt-3">
      <v-btn
        @click="upload"
        color="primary"
        :disabled="isButtonDisabled"
        style="margin: auto; max-width: 320px;"
      >
        Upload
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { getDateTimeFromDateAsKey1 } from "@/common/utilities/date";
import { getUploadData } from "@/store/offsiteService";

import firebase from "firebase/app";
import "firebase/storage";

export default {
  props: {
    header: String,
    resultCode: String,
    serviceId: String,
    userCode: String
  },

  data: () => ({
    lastSubmissionDate: null,
    submissionUrl: "",
    targetFile: ""
  }),

  mounted() {
    this.loadData();
  },

  computed: {
    isButtonDisabled() {
      return this.targetFile ? false : true;
    }
  },

  watch: {
    resultCode() {
      this.loadData();
    },

    serviceId() {
      this.loadData();
    },

    userCode() {
      this.loadData();
    }
  },

  methods: {
    loadData() {
      this.lastSubmissionDate = "";
      this.submissionUrl = "";

      getUploadData(this.serviceId, this.userCode, this.resultCode).then(
        data => {
          this.lastSubmissionDate = data.timestamp || "";
          this.submissionUrl = data.url || "";
        }
      );
    },

    selectFile(e) {
      this.targetFile = e.target.files[0];
    },

    upload() {
      const filename = `medical/${this.userCode}-${
        this.resultCode
      }-${getDateTimeFromDateAsKey1()}`;

      firebase
        .storage()
        .ref(filename)
        .put(this.targetFile)
        .then(result => {
          this.$emit("onSave", {
            resultCode: this.resultCode,
            fullPath: result.metadata ? result.metadata.fullPath || "" : ""
          });
          this.targetFile = "";
        })
        .finally(() => {
          this.loadData();
        });
    }
  }
};
</script>
