<template>
  <v-row>
    <v-col cols="1" />
    <v-col class="mt-n4" cols="10">
      <v-select
        v-model="serviceDetails"
        @change="serviceSelected"
        :items="services"
        item-text="name"
        item-value="details"
        :label="getLabel()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { timeStampToDate } from "@/common/utilities/date";

export default {
  name: "SelectService",

  props: {
    isWalkin: Boolean,
    resetToggle: Boolean
  },

  data: () => ({
    serviceDetails: {},
    services: []
  }),

  mounted() {
    this.option = "After";

    this.$store.dispatch("getServices", this.isWalkin).then(docs => {
      this.services = [];
      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      for (const doc of docs) {
        const schedule = timeStampToDate(doc.schedule);
        this.services.push({
          name: doc.name,
          details: {
            id: doc.id,
            location: doc.location,
            schedule: schedule,
            serviceName: doc.name
          }
        });
      }
    });
  },

  methods: {
    getLabel() {
      return this.isWalkin ? "Walk in Schedules" : "Service Name";
    },

    serviceSelected() {
      this.$emit("setSelectedId", this.serviceDetails || "");
      this.$emit("onSelectedService", this.serviceDetails || {});
    }
  },

  watch: {
    resetToggle() {
      this.serviceDetails = {};
    }
  }
};
</script>
