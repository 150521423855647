<template>
  <v-container fluid class="menucontainer text-center">
    Sorry. You do not have rights to access this app.
    <br />
    <span style="font-size: smaller;">
      Please contact
      <a href="mailto: medisureteam@medisureonline.com">Medisure Team</a>
      for inquiries.
    </span>
  </v-container>
</template>

<script>
import { signOut } from "../main.js";

export default {
  name: "NotAllowed",

  mounted() {
    signOut();
  }
};
</script>

<style scoped>
.menucontainer {
  max-width: 980px;
}
</style>
