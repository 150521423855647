<template>
  <v-container fluid class="signin-container">
    <div v-if="waitSpinner" class="text-center">
      <v-progress-circular
        :size="150"
        :width="7"
        color="purple"
        indeterminate
      />
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" sm="6" align="center">
          <v-img
            src="@/assets/common/logo.png"
            alt="MediSure"
            max-width="440"
            min-width="220"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-alert type="error" v-if="errorText">
            {{ errorText }}
          </v-alert>

          <v-row>
            <v-col align="center">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                />

                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  label="Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="showPassword = !showPassword"
                />

                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="uname_pass_login"
                >
                  Sign in
                </v-btn>
              </v-form>
            </v-col>
          </v-row>

          <v-divider inset class="mt-5 mb-5" />

          <v-row>
            <v-col align="center">
              <v-btn icon large @click="google_login">
                <v-img
                  src="@/assets/common/btn_google_signin_dark_normal_web.png"
                  alt="Sign in with Google"
                />
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

import { signInEmail } from "../main";

export default {
  name: "Signin",

  data: () => ({
    valid: true,
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    showPassword: false,
    password: "",
    rules: {
      required: value => !!value || "Pasword Required.",
      min: v => v.length >= 8 || "Min 8 characters"
    },
    errorText: "",
    waitSpinner: false
  }),

  methods: {
    async google_login() {
      this.$router.replace({ name: "Loading" });

      this.waitSpinner = true;
      this.errorText = "";
      const provider = new firebase.auth.GoogleAuthProvider();
      await firebase
        .auth()
        .signInWithRedirect(provider)
        .then(() => {
          this.$router.replace({ name: "NotAllowed" });
        })
        .catch(error => {
          this.waitSpinner = false;
          this.errorText = error;
        });
    },

    uname_pass_login() {
      this.waitSpinner = true;
      this.errorText = "";
      this.$refs.form.validate();

      signInEmail(this.email, this.password).catch(error => {
        this.waitSpinner = false;
        this.errorText = error.message.substring(
          error.message.indexOf(":") + 1
        );
      });
    }
  }
};
</script>

<style scoped>
.signin-container {
  max-width: 1200px;
}
</style>
