<template>
  <v-container fluid class="main-container" style="text-align: center;">
    <PageHeader header="Add Fecalysis Result" class=" mb-10" />

    <UserCode
      ref="userCodeComponent"
      @onInvalidUserCode="onInvalidUserCode"
      @onValidUserCode="onValidUserCode"
    />

    <UserInfo :code="userCode" @onData="onData" />

    <v-row class="mt-5" v-show="isUserCodeValid && serviceId">
      <v-col cols="12">
        Macroscopic
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Color"
          v-model="thisData.color"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Consistency"
          v-model="thisData.consistency"
          class="text-field-style"
        />
      </v-col>
    </v-row>

    <v-row class="mt-10" v-show="isUserCodeValid && serviceId">
      <v-col cols="12">
        Microscopic
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="WBC"
          v-model="thisData.wbc"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="RBC"
          v-model="thisData.rbc"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Fat Globules"
          v-model="thisData.fatGlobules"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Yeast Cells"
          v-model="thisData.yeastCells"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Bacteria"
          v-model="thisData.bacteria"
          class="text-field-style"
        />
      </v-col>
    </v-row>

    <v-row class="mt-10" v-show="isUserCodeValid && serviceId">
      <v-col
        v-for="(parasite, i) in thisData.parasites"
        :key="i"
        ref="parasites"
        cols="12"
        class="fit-in-row py-0"
      >
        <Parasite
          class="py-0"
          :isAddVisible="i === thisData.parasites.length - 1"
          :isRemoveVisible="thisData.parasites.length > 1"
          :hpo.sync="parasite.hpo"
          :parasite.sync="parasite.parasite"
          @addNew="addNew"
          @removeThis="removeThis"
        />
      </v-col>
    </v-row>

    <v-row class="mt-5" v-show="isUserCodeValid && serviceId">
      <v-col cols="12">
        Remarks
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row" style="margin: auto;">
        <v-textarea
          label="Remarks"
          v-model="thisData.remarks"
          style="max-width: 600px;"
        />
      </v-col>
    </v-row>

    <v-row class="mt-15" v-show="isUserCodeValid && serviceId">
      <v-btn
        :disabled="isSaveButtonDisabled"
        @click="addFecalysisSample"
        color="primary"
        style="margin: auto; max-width: 320px;"
      >
        Save Fecalysis Result
      </v-btn>
    </v-row>

    <ErrorMessage :errorMessage="errorMessage" />

    <ConfirmDlg ref="confirm" />

    <SubmissionSummary
      :activeServiceId="serviceId"
      headerText1="Has Fecal Sample?"
      :headerValue1="STAGE.FECALYSIS"
      headerText2="Has Fecalysis Data?"
      :headerValue2="DATA.FECALYSIS"
      :key="submissionKey"
    />
  </v-container>
</template>

<script>
import { DATA, STAGE, getActiveOffsiteService } from "@/store/offsiteService";

import {
  addFecalysisData,
  getFecalysisData,
  getFecalysisDataFromResult
} from "../store";

import ConfirmDlg from "@/components/ConfirmDlg.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import PageHeader from "@/components/PageHeader.vue";
import Parasite from "../components/Parasite.vue";
import SubmissionSummary from "@/components/SubmissionSummary2.vue";
import UserCode from "@/components/UserCode.vue";
import UserInfo from "@/components/UserInfo.vue";

export default {
  name: "AddFecalysisResult",

  components: {
    ConfirmDlg,
    ErrorMessage,
    PageHeader,
    Parasite,
    SubmissionSummary,
    UserCode,
    UserInfo
  },

  data: () => ({
    errorMessage: "",

    isUserCodeValid: false,
    userCode: "",

    serviceId: "",

    submissionKey: 0,

    thisData: {},
    lastData: {},

    DATA: DATA,
    STAGE: STAGE
  }),

  mounted() {
    const activeService = getActiveOffsiteService();
    this.serviceId =
      activeService && activeService.id ? activeService.id || "" : "";

    this.thisData = getFecalysisDataFromResult(null);
    this.lastData = { ...this.thisData };
    this.submissionKey++;
  },

  methods: {
    addFecalysisSample() {
      addFecalysisData(this.serviceId, this.userCode, this.thisData).then(
        () => {
          this.submissionKey++;
          this.$refs.confirm.open(
            "Confirmation",
            "Fecalysis data upload completed",
            {
              noconfirm: true
            }
          );
        }
      );
    },

    addNew() {
      this.thisData.parasites.push({ hpo: "", parasite: "" });
    },

    areParasitesArrayEqual(array1, array2) {
      if (array1.length !== array2.length) {
        return false;
      }

      for (let i in array1.parasites) {
        if (
          array1[i].hpo !== array2[i].hpo ||
          array1[i].parasite !== array2[i].parasite
        ) {
          return false;
        }
      }

      return true;
    },

    onData(data) {
      this.userData = data;

      if (this.isUserCodeValid) {
        this.errorMessage = "Looking up user code. Please wait.";
        this.isSaved = false;

        if (data.name) {
          getFecalysisData(this.serviceId, this.userCode)
            .then(result => {
              this.lastData = getFecalysisDataFromResult(result);
              this.thisData = { ...this.lastData };
              this.errorMessage = "";
            })
            .catch(
              err => (this.errorMessage = `Get submissions error: ${err}`)
            );
        } else {
          this.errorMessage = "Attendee cannot be found in the database";
        }
      } else {
        this.errorMessage = "";
      }
    },

    onInvalidUserCode(userCode) {
      this.userCode = userCode;
      this.isUserCodeValid = false;
      this.thisData = getFecalysisDataFromResult(null);
    },

    onValidUserCode(userCode) {
      this.userCode = userCode;
      this.isUserCodeValid = true;
    },

    removeThis(data) {
      this.thisData.parasites.splice(
        this.thisData.parasites.findIndex(currentValue => {
          return (
            currentValue.hpo === data.hpo &&
            currentValue.parasite === data.parasite
          );
        }),
        1
      );
    }
  },

  computed: {
    areDataUnchanged() {
      return (
        this.thisData.color === this.lastData.color &&
        this.thisData.consistency === this.lastData.consistency &&
        this.thisData.wbc === this.lastData.wbc &&
        this.thisData.rbc === this.lastData.rbc &&
        this.thisData.fatGlobules === this.lastData.fatGlobules &&
        this.thisData.yeastCells === this.lastData.yeastCells &&
        this.thisData.bacteria === this.lastData.bacteria &&
        this.areParasitesArrayEqual(
          this.thisData.parasites,
          this.lastData.parasites
        ) &&
        this.thisData.remarks === this.lastData.remarks
      );
    },

    isDataIncomplete() {
      return (
        !this.thisData.color ||
        !this.thisData.consistency ||
        !this.thisData.wbc ||
        !this.thisData.rbc ||
        !this.thisData.fatGlobules ||
        !this.thisData.yeastCells ||
        !this.thisData.bacteria
      );
    },

    isSaveButtonDisabled() {
      return (
        !this.isUserCodeValid || this.isDataIncomplete || this.areDataUnchanged
      );
    }
  }
};
</script>

<style scoped>
.fit-in-row {
  padding-bottom: 0;
  padding-top: 0;
}

.text-field-style {
  margin: auto;
  max-width: 320px;
}
</style>
