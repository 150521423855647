// OLD home service status
export const REQUESTED = "requested";
export const AGREED = "agreed";
export const CANCELLED = "cancelled";
export const FORPAYMENTVERIFICATION = "for payment verification";

// appointment status
export const SCHEDULED = "scheduled";
export const ONGOING = "ongoing";
export const COMPLETED = "completed";
export const ATTENDED = "attended";

// home service status
export const ALLOWED = "allowed";
export const REBOOKED = "rebooked";
export const REJECTED = "rejected";

// --------------------------- APPOINTMENT STATUS ---------------------------//
// STATUS_REQUESTED => STATUS_FOR_CONFIRMATION
//                 (=> STATUS_FOR_PAYMENT_VERIFICATION)
//                  => STATUS_CONFIRMED

export const STATUS_CANCELLED = "cancelled";
export const STATUS_CONFIRMED = "confirmed";
export const STATUS_FOR_CONFIRMATION = "for confirmation";
export const STATUS_FOR_PAYMENT = "for payment";
export const STATUS_PAYMENT_VERIFICATION = "payment verification";
export const STATUS_FOR_PAYMENT_VERIFICATION = "for payment verification";
export const STATUS_REQUESTED = "requested";

export function isForConfirmation(status) {
  return STATUS_FOR_CONFIRMATION === status;
}

export function isForPaymentVerification(status) {
  return STATUS_FOR_PAYMENT_VERIFICATION === status;
}

export function isRequested(status) {
  return STATUS_REQUESTED === status;
}

// ------------------------------- CLINIC TYPE -------------------------------//

export const CLINIC_TYPE_HOME_SERVICE = "Home Service";
export const CLINIC_TYPE_MOBILE_VAN = "Mobile";
export const CLINIC_TYPE_STATIONARY = "Stationary";

export function isHomeService(clinicType) {
  return CLINIC_TYPE_HOME_SERVICE === clinicType;
}

export function isStationaryClinic(clinicType) {
  return CLINIC_TYPE_STATIONARY === clinicType;
}

// ------------------------------- TRANSACTION TYPE -------------------------------//

export const TRANSACTION_TYPE_APPOINTMENT = "Appointment";
export const TRANSACTION_TYPE_SCHEDULE = "Schedule";
export const TRANSACTION_TYPE_WALKIN = "Walk-in";

// -------------------------------- UTILITIES --------------------------------//

import { getDateTime, getTime } from "./date";

export function getScheduleString(appointment) {
  let start = appointment.start ? getDateTime(appointment.start) : "";
  let end = appointment.end ? getTime(appointment.end) : "";

  return start && end ? `${start} - ${end}` : start || end || "";
}
