<template>
  <div>
    <div class="text-center">
      <img
        style="display: block; margin-left: auto; margin-right: auto; height: 100px;"
        src="@/assets/common/logo.png"
        alt="Medisure Logo"
      />

      <table style="font-size: 13px; margin-bottom: 10px; margin: auto;">
        <caption>
          Header
        </caption>

        <th />

        <tr>
          <td>
            <span style="font-size: 16px; font-weight: bold;">
              MEDISURE PLUS DIAGNOSTIC LABORATORY
            </span>
          </td>
        </tr>

        <tr>
          <td class="font-bold">MEDISURE ONLINE INC.</td>
        </tr>
        <tr>
          <td class="font-11">
            16B WILLIAMS ST. HIGHWAY HILLS, MADALUYONG CITY
          </td>
        </tr>

        <tr>
          <td class="font-11">
            TEL. NO.: 864-28781 &bull; EMAIL ADDRESS: msolab16@gmail.com
          </td>
        </tr>
      </table>
    </div>

    <table class="mt-5" style="font-size: 13px; margin: auto;">
      <caption>
        User Information
      </caption>

      <th />

      <tr>
        <td style="width: 50" />
        <td style="width: 75" />
        <td style="width: 125" />
        <td class="header-2" style="width: 100">USER CODE</td>
        <td class="font-content" style="width: 100">{{ USER_CODE }}</td>
        <td class="header-2" style="width: 100">DATE</td>
        <td class="font-content" style="width: 100">{{ DATE }}</td>
      </tr>

      <tr>
        <td class="header-1">NAME</td>
        <td class="font-content" colspan="4">{{ NAME }}</td>
        <td class="header-2">AGE / SEX</td>
        <td class="font-content">{{ AGE_SEX }}</td>
      </tr>

      <tr>
        <td class="header-1">DIAGNOSIS</td>
        <td class="font-content" colspan="4"></td>
        <td class="header-2">BIRTH DATE</td>
        <td class="font-content">{{ BIRTHDATE }}</td>
      </tr>

      <tr>
        <td class="header-1" colspan="2">ATTENDING PHYSICIAN</td>
        <td class="font-content" colspan="3"></td>
        <td class="header-2">CONTACT NO.</td>
        <td class="font-content">{{ PHONE }}</td>
      </tr>
    </table>

    <p class="font-bold mt-10 text-center">
      LABORATORY REQUEST FORM (INDIVIDUAL TEST)
    </p>

    <!-- 700 px -->
    <table class="mt-5" style="font-size: 11px; margin: auto;">
      <caption>
        Lab Results
      </caption>

      <th />

      <tr>
        <td class="font-bold" style="width: 150">HEMATOLOGY</td>
        <td style="width: 50" />
        <td style="width: 25" />
        <td class="font-bold" style="width: 150">CLINICAL MICROSCOPY</td>
        <td style="width: 50" />
        <td style="width: 25" />
        <td class="font-bold" style="width: 150">RADIOLOGY</td>
        <td style="width: 50" />
      </tr>

      <tr>
        <td>&#9744; CBC</td>
        <td style="text-align: right;">215.00</td>
        <td />
        <td>&#9744; URINALYSIS</td>
        <td style="text-align: right;">120.00</td>
        <td />
        <td>&#9744; CHEST X-RAY</td>
        <td />
      </tr>

      <tr>
        <td>&#9744; PROTHROMBIN TIME</td>
        <td />
        <td />
        <td>&#9744; FECALYSIS</td>
        <td style="text-align: right;">120.00</td>
        <td />
        <td>&#9744; ULTRASOUND</td>
        <td />
      </tr>

      <tr>
        <td>&#9744; PTT</td>
        <td />
        <td />
        <td>&#9744; SEMENALYSIS</td>
      </tr>

      <tr>
        <td>&#9744; BLEEDING TIME</td>
        <td />
        <td />
        <td>&#9744; FOBT</td>
        <td />
        <td />
        <td class="font-bold">CARDIOLOGY</td>
        <td />
      </tr>

      <tr>
        <td>&#9744; CLOTTING TIME</td>
        <td />
        <td />
        <td>&#9744; PREGNANCY TEST</td>
        <td />
        <td />
        <td>&#9744; ECG</td>
        <td />
      </tr>

      <tr>
        <td>&#9744; ESR</td>
        <td style="text-align: right;">150.00</td>
      </tr>

      <tr>
        <td>
          &#9744; <span style="font-size: 9px;">PERIPHERAL BLOOD SMEAR</span>
        </td>
      </tr>

      <tr>
        <td>&#9744; BLOOD TYPING</td>
        <td style="text-align: right;">180.00</td>
      </tr>

      <tr>
        <td>&nbsp;</td>
      </tr>

      <tr>
        <td class="font-bold">CLINICAL CHEMISTRY</td>
      </tr>

      <tr>
        <td>&#9744; FBS</td>
        <td />
        <td />
        <td class="font-bold">&#9744; LIPID PROFILE</td>
        <td />
        <td />
        <td class="font-bold">&#9744; LIVER PROFILE</td>
        <td />
      </tr>

      <tr>
        <td>&#9744; SGPT (ALT)</td>
        <td />
        <td />
        <td>&#9744; CHOLESTEROL</td>
        <td style="text-align: right;">180.00</td>
        <td />
        <td>&#9744; SGPT (ALT)</td>
      </tr>

      <tr>
        <td>&#9744; SGOT (AST)</td>
        <td />
        <td />
        <td>&#9744; TRIGLYCERIDES</td>
        <td />
        <td />
        <td>&#9744; SGOT (AST)</td>
      </tr>

      <tr>
        <td>&#9744; PHOSPHORUS</td>
        <td style="text-align: right;">200.00</td>
        <td />
        <td>&#9744; HDL / LDL / VLDL</td>
        <td />
        <td />
        <td>&#9744; ALKALINE PHOSPHORUS</td>
      </tr>

      <tr>
        <td>&#9744; LIPASE</td>
        <td style="text-align: right;">300.00</td>
        <td />
        <td />
        <td />
        <td />
        <td>&#9744; TPAG</td>
      </tr>

      <tr>
        <td>&#9744; AMYLASE</td>
        <td style="text-align: right;">220.00</td>
        <td />
        <td class="font-bold">&#9744; KIDNEY PROFILE</td>
        <td />
        <td />
        <td>&#9744; BILIRUBIN</td>
        <td style="text-align: right;">180.00</td>
      </tr>

      <tr>
        <td>&#9744; HBA1C</td>
        <td style="text-align: right;">700.00</td>
        <td />
        <td>&#9744; BUN</td>
        <td style="text-align: right;">180.00</td>
      </tr>

      <tr>
        <td>&#9744; TOTAL PROTEIN</td>
        <td style="text-align: right;">200.00</td>
        <td />
        <td>&#9744; CREATININE</td>
        <td style="text-align: right;">180.00</td>
        <td />
        <td class="font-bold">&#9744; ELECTROLYTES</td>
      </tr>

      <tr>
        <td />
        <td />
        <td />
        <td>&#9744; BUA</td>
        <td />
        <td />
        <td>&#9744; SODIUM</td>
        <td style="text-align: right;">250.00</td>
      </tr>

      <tr>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td>&#9744; POTASSIUM</td>
        <td style="text-align: right;">200.00</td>
      </tr>

      <tr>
        <td />
        <td />
        <td />
        <td class="font-bold">&#9744; HEPATITIS</td>
        <td />
        <td />
        <td>&#9744; CHLORIDE</td>
      </tr>

      <tr>
        <td />
        <td />
        <td />
        <td>&#9744; HBSAG (QUALITATIVE)</td>
        <td />
        <td />
        <td>&#9744; CALCIUM</td>
        <td style="text-align: right;">200.00</td>
      </tr>

      <tr>
        <td />
        <td />
        <td />
        <td>&#9744; ANTI-HCV (QUALITATIVE)</td>
        <td style="text-align: right;">900.00</td>
        <td />
        <td>&#9744; MAGNESIUM</td>
        <td style="text-align: right;">200.00</td>
      </tr>
    </table>

    <table class="mt-5" style="font-size: 13px; margin: auto;">
      <caption>
        Other Tests
      </caption>

      <th />

      <tr>
        <td style="width: 200">OTHER TESTS / REMARKS:</td>
        <td class="border-bottom" style="width: 450" />
      </tr>

      <tr>
        <td colspan="2" class="border-bottom">&nbsp;</td>
      </tr>

      <tr>
        <td colspan="2" class="border-bottom">&nbsp;</td>
      </tr>
    </table>

    <v-divider inset class="mt-10 mb-5" style="margin: auto; width: 650px;" />

    <p class="font-11 font-bold text-center">
      ROUTING
    </p>

    <table style="font-size: 11px; margin: auto;">
      <caption>
        Checklist
      </caption>

      <th />

      <tr>
        <td style="width: 225">&#9744; 1. REGISTRATION</td>
        <td style="width: 25" />
        <td style="width: 225">&#9744; 2. X-RAY</td>
        <td style="width: 25" />
        <td style="width: 150">&#9744; 3. URINE</td>
      </tr>

      <tr>
        <td />
        <td />
        <td>&#9744; 4. BLOOD EXTRACTION</td>
        <td />
        <td>&#9744; 5. ECG</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getDate } from "@/common/utilities/date";

export default {
  name: "LabRequestForm",

  data: () => ({
    DATE: getDate(new Date()),

    AGE_SEX: "",
    BIRTHDATE: "",
    NAME: "",
    PHONE: "",
    USER_CODE: ""
  }),

  mounted() {
    this.AGE_SEX = this.$route.query && this.$route.query.AGE_SEX;
    this.BIRTHDATE = this.$route.query && this.$route.query.BIRTHDATE;
    this.NAME = this.$route.query && this.$route.query.NAME;
    this.PHONE = this.$route.query && this.$route.query.PHONE;
    this.USER_CODE = this.$route.query && this.$route.query.USER_CODE;
  }
};
</script>

<style scoped>
.border-bottom {
  border-bottom: solid 1px;
}

.font-bold {
  font-weight: bold;
}

.font-content {
  font-size: 14px;
  font-weight: bold;
  border-bottom: solid 1px;
}

.font-11 {
  font-size: 11px;
}

.header-1 {
  vertical-align: bottom;
}

.header-2 {
  padding-left: 10px;
  vertical-align: bottom;
}
</style>
