<template>
  <div>
    <v-divider class="mb-10" />

    <v-data-table :items-per-page="15" dense :headers="headers" :items="items">
      <template v-slot:item="row">
        <tr>
          <td class="text-center">
            {{ row.item[headerValue1] ? "&check;" : "" }}
          </td>
          <td class="text-center">
            {{ row.item[headerValue2] ? "&check;" : "" }}
          </td>
          <td class="text-center">{{ row.item.userCode }}</td>
          <td class="text-left">{{ row.item.lastName }}</td>
          <td class="text-left">{{ row.item.firstName }}</td>
          <td class="text-left">{{ row.item.middleName }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { HEADER, getSubmissions } from "@/store/offsiteService";

export default {
  props: {
    activeServiceId: String,
    headerText1: String,
    headerValue1: String,
    headerText2: String,
    headerValue2: String
  },

  data: () => ({
    headers: [
      { text: "", value: "", align: "center" },
      { text: "", value: "", align: "center" },
      { text: "User Code", value: "userCode", align: "center" },
      { text: "Last Name", value: "lastName", align: "center" },
      { text: "First Name", value: "firstName", align: "center" },
      { text: "Middle Name", value: "middleName", align: "center" }
    ],
    items: []
  }),

  mounted() {
    this.headers[0].text = this.headerText1;
    this.headers[0].value = this.headerValue1;

    this.headers[1].text = this.headerText2;
    this.headers[1].value = this.headerValue2;

    this.getSubmissions();
  },

  methods: {
    getSubmissions() {
      return new Promise(resolve => {
        this.items = [];

        if (this.activeServiceId) {
          getSubmissions(this.activeServiceId).then(data => {
            for (const key in data) {
              this.items.push({
                ...data[key],
                [HEADER.USER_CODE]: key,
                [this.headerValue1]: data[key][this.headerValue1],
                [this.headerValue2]: data[key][this.headerValue2]
              });
            }

            resolve();
          });
        } else {
          resolve();
        }
      });
    }
  }
};
</script>
