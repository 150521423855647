<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="py-0 text-center" style="font-weight: bold;">
        {{ name }} {{ gender }}
      </v-col>
      <v-col cols="12" class="py-0 text-center">
        {{ birthday }}{{ age }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAge } from "@/common/utilities/date";
import { getUserDetails } from "@/Registration/store";

export default {
  name: "UserInfo",

  props: {
    code: String
  },

  data: () => ({
    name: "",
    image: "",
    age: "",
    birthday: "",
    gender: ""
  }),

  watch: {
    code(value) {
      getUserDetails(value).then(details => {
        this.name = details.firstname
          ? `${details.firstname} ${details.lastName}`
          : "";
        this.birthday = details.birthday || "";
        this.age = details.birthday
          ? ` (${getAge(new Date(details.birthday))} years old)`
          : "";
        this.gender = details.gender ? `(${details.gender})` : "";

        this.$emit("onData", {
          id: details.id,
          name: this.name,
          image: this.image,
          age: this.age,
          birthday: this.birthday,
          gender: details.gender || "",
          phone: details.phone
        });
      });
    }
  }
};
</script>
