import { padLeftZero } from "./number";

let moment = require("moment");

function GetValue(h, m, meridien) {
  if (12 === h) {
    h = 0;
  }

  if ("PM" === meridien) {
    h += 12;
  }

  return h * 100 + m;
}

export function GetTime(h, m, meridien) {
  return {
    text: `${padLeftZero(h)}:${padLeftZero(m)} ${meridien}`,
    value: GetValue(h, m, meridien)
  };
}

export function GetTimeFromValue(value) {
  let m = value % 100;
  let h = (value - m) / 100;
  let meridien = h < 12 ? "AM" : "PM";

  h = h % 12 || 12;

  return {
    text: `${padLeftZero(h)}:${padLeftZero(m)} ${meridien}`,
    value: GetValue(h, m, meridien)
  };
}

/**
 * @param {Date} date
 * @param {string} time
 */
export function MergeDateAndTime(date, time) {
  var timeSplit = time.split(":");
  date.setHours(timeSplit[0]);
  date.setMinutes(timeSplit[1]);
  date.setSeconds(timeSplit[2]);

  return new Date(date.getTime());
}

/**
 * @param {string} date
 * @param {string} time
 */
export function MergeDateAndTimeStrings(date, time) {
  return new Date(`${date} ${time}`);
}

export function displayAmPmTime(time) {
  if (time) {
    let timeString = `${time}:00`;
    let H = Number(timeString.substring(0, 2));
    let h = H % 12 || 12;
    let ampm = H < 12 ? " AM" : " PM";
    return h + timeString.substring(2, 5) + ampm;
  }

  return "00:00";
}

export function GetTimeFromDate(date) {
  return GetTimeFromValue(date.getHours() * 100 + date.getMinutes());
}

export function GetTimeFromTimestamp(timestamp) {
  return moment(timestamp.seconds * 1000).format("hh:mm:ss A");
}

export function GetShortTimeFromTimestamp(timestamp) {
  return moment(timestamp.seconds * 1000).format("hh:mm A");
}

export function GetSlotCompare(timestamp) {
  return new Date(timestamp.seconds * 1000);
}
