<template>
  <div class="text-center">
    <v-progress-circular :size="150" :width="7" color="purple" indeterminate />
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>
