<template>
  <v-container fluid class="main-container">
    <PageHeader
      header="Sample Submission"
      :subheader="activeService.name || 'No service selected'"
    />

    <MenuItems />
  </v-container>
</template>

<script>
import { getActiveOffsiteService } from "@/store/offsiteService";

import MenuItems from "../components/MenuItems.vue";
import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "Menu",

  components: {
    MenuItems,
    PageHeader
  },

  mounted() {
    this.activeService = getActiveOffsiteService();
  },

  data: () => ({
    activeService: { id: null, name: null }
  })
};
</script>
