<template>
  <v-container class="text-center">
    <PageHeader
      header="Set Active Service"
      :subheader="activeService.name || 'No service selected'"
    />

    <v-card-actions class="justify-center">
      <v-switch v-model="isWalkin" :label="getText()" />
    </v-card-actions>

    <SelectService
      class="mt-5"
      :key="isWalkin"
      :isWalkin="isWalkin"
      :resetToggle="resetToggle"
      @setSelectedId="setSelectedService"
    />

    <v-btn
      v-if="activeService.id"
      class="mt-10"
      color="primary"
      @click="resetService"
    >
      Reset Active Service
    </v-btn>
  </v-container>
</template>

<script>
import {
  getActiveOffsiteService,
  setActiveOffsiteService
} from "@/store/offsiteService";

import PageHeader from "@/components/PageHeader.vue";
import SelectService from "@/components/SelectService.vue";

export default {
  name: "SetActiveService",

  components: {
    PageHeader,
    SelectService
  },

  mounted() {
    this.activeService = getActiveOffsiteService();
  },

  data: () => ({
    activeService: { id: null, name: null, schedule: null },
    isWalkin: false,
    resetToggle: false
  }),

  methods: {
    getText() {
      return this.isWalkin ? "Walk in" : "All";
    },

    resetService() {
      setActiveOffsiteService(null);
      this.activeService = { id: null, name: null, schedule: null };
      this.resetToggle = !this.resetToggle;
    },

    setSelectedService(details) {
      setActiveOffsiteService(details);

      this.activeService = {
        id: details.id,
        name: details.serviceName,
        schedule: details.schedule
      };
    }
  }
};
</script>
