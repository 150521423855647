<template>
  <v-container class="inline-block">
    <v-text-field
      label="Parasite"
      v-model="parasiteLocal"
      @change="$emit('update:parasite', parasiteLocal)"
      class="inline-block parasite pt-0"
    />

    <v-text-field
      label="/HPO"
      v-model="hpoLocal"
      @change="$emit('update:hpo', hpoLocal)"
      class="arrange-contents inline-block pt-0 px-100"
    />

    <div class="inline-block">
      <v-btn
        color="primary"
        class="arrange-contents"
        :style="{ visibility: isAddVisible ? 'visible' : 'collapse' }"
        @click="$emit('addNew')"
      >
        Add New
      </v-btn>
    </div>

    <div
      class="inline-block"
      :style="{ visibility: isRemoveVisible ? 'visible' : 'collapse' }"
    >
      <v-btn
        color="primary"
        class="arrange-contents"
        @click="$emit('removeThis', { hpo: hpoLocal, parasite: parasiteLocal })"
      >
        Remove This
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Parasite",

  props: {
    isAddVisible: Boolean,
    isRemoveVisible: Boolean,
    hpo: String,
    parasite: String
  },

  data: () => ({
    hpoLocal: "",
    parasiteLocal: ""
  }),

  mounted() {
    this.hpoLocal = this.hpo;
    this.parasiteLocal = this.parasite;
  },

  watch: {
    hpo() {
      this.hpoLocal = this.hpo;
    },

    parasite() {
      this.parasiteLocal = this.parasite;
    }
  }
};
</script>

<style scoped>
.arrange-contents {
  margin: auto;
  margin-left: 20px;
  margin-right: 0;
}

.inline-block {
  display: inline-block;
}

.parasite {
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  max-width: 320px;
}

.px-100 {
  max-width: 100px;
}
</style>
