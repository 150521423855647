import { DB } from "@/common/store";
import { DATA, STAGE, getLastSubmission } from "@/store/offsiteService";

import { db } from "@/main";

export function addData(scheduleId, userCode, type, data) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .add({
        ...data,
        scheduleId: scheduleId,
        timestamp: new Date(),
        type: type,
        userCode: userCode,
        value: true
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function addFecalysisData(scheduleId, userCode, data) {
  return addData(scheduleId, userCode, DATA.FECALYSIS, data);
}

export function addUrinalysisData(scheduleId, userCode, data) {
  return addData(scheduleId, userCode, DATA.URINALYSIS, data);
}

function processSnapshot(snapshot) {
  let submissions = {
    fecalysis: false,
    photo: "",
    temperature: "",
    urinalysis: false,
    xray: false
  };

  snapshot.forEach(doc => {
    const data = doc.data();
    if (STAGE.CHECKIN === data.type) {
      submissions.photo = data.photo || "";
      submissions.temperature = data.temperature || "";
    }
  });

  return submissions;
}

export function getCheckinData(scheduleId, userCode) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .where("scheduleId", "==", scheduleId)
      .where("userCode", "==", userCode)
      .get()
      .then(snapshot => {
        resolve(processSnapshot(snapshot));
      })
      .catch(err => reject(err));
  });
}

function lastFecalysis(snapshot) {
  let lastSubmission = null;
  let fecalysis = false;

  snapshot.forEach(doc => {
    const data = doc.data();
    if (!lastSubmission || lastSubmission < data.timestamp) {
      lastSubmission = data.timestamp;
      fecalysis = true;
    }
  });

  return { fecalysis: fecalysis };
}

export function getFecalysisDataFromResult(result) {
  return {
    color: !result ? "" : result.color,
    consistency: !result ? "" : result.consistency,

    wbc: !result ? "" : result.wbc,
    rbc: !result ? "" : result.rbc,
    fatGlobules: !result ? "" : result.fatGlobules,
    yeastCells: !result ? "" : result.yeastCells,
    bacteria: !result ? "" : result.bacteria,

    parasites:
      result && result.parasites
        ? [...result.parasites]
        : [{ hpo: "", parasite: "" }],

    remarks: !result ? "" : result.remarks
  };
}

function lastFecalysisData(snapshot) {
  let fecalysisData = {};

  snapshot.forEach(doc => {
    fecalysisData = getFecalysisDataFromResult(doc.data());
  });

  return fecalysisData;
}

function lastUrinalysis(snapshot) {
  let lastSubmission = null;
  let urinalysis = false;

  snapshot.forEach(doc => {
    const data = doc.data();
    if (!lastSubmission || lastSubmission < data.timestamp) {
      lastSubmission = data.timestamp;
      urinalysis = true;
    }
  });

  return { urinalysis: urinalysis };
}

function getValue(obj, key) {
  return obj && obj[key] ? obj[key] : "";
}

export function getUrinalysisDataFromResult(result) {
  return {
    color: getValue(result, "color"),
    clarity: getValue(result, "clarity"),

    wbc: getValue(result, "wbc"),
    rbc: getValue(result, "rbc"),
    epithelialCells: getValue(result, "epithelialCells"),
    bacteria: getValue(result, "bacteria"),
    mucusThread: getValue(result, "mucusThread"),
    amorphousUrates: getValue(result, "amorphousUrates"),
    amorphousPhosphate: getValue(result, "amorphousPhosphate"),
    yeastCells: getValue(result, "yeastCells"),

    urobilinogen: getValue(result, "urobilinogen"),
    bilirubin: getValue(result, "bilirubin"),
    protein: getValue(result, "protein"),
    blood: getValue(result, "blood"),
    ketone: getValue(result, "ketone"),
    nitrite: getValue(result, "nitrite"),
    wbcChemicalExamination: getValue(result, "wbcChemicalExamination"),
    glucose: getValue(result, "glucose"),
    specificGravity: getValue(result, "specificGravity"),
    ph: getValue(result, "ph"),

    remarks: getValue(result, "remarks")
  };
}

function lastUrinalysisData(snapshot) {
  let urinalysisData = {};

  snapshot.forEach(doc => {
    urinalysisData = getUrinalysisDataFromResult(doc.data());
  });

  return urinalysisData;
}

export function getFecalysis(scheduleId, userCode) {
  return getLastSubmission(scheduleId, userCode, DATA.FECALYSIS, lastFecalysis);
}

export function getFecalysisData(scheduleId, userCode) {
  return getLastSubmission(
    scheduleId,
    userCode,
    DATA.FECALYSIS,
    lastFecalysisData
  );
}

export function getUrinalysis(scheduleId, userCode) {
  return getLastSubmission(
    scheduleId,
    userCode,
    DATA.URINALYSIS,
    lastUrinalysis
  );
}

export function getUrinalysisData(scheduleId, userCode) {
  return getLastSubmission(
    scheduleId,
    userCode,
    DATA.URINALYSIS,
    lastUrinalysisData
  );
}
