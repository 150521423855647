<template>
  <v-app-bar flat app dark color="rgb(1, 153, 6)">
    <v-toolbar-title>
      <span class="grey--text text--lighten-1">Medi</span>
      <span class="grey--text text--lighten-2">Sure</span>
      <span class="grey--text text--lighten-1">&nbsp;Offsite Services</span>
    </v-toolbar-title>

    <v-spacer />
    <v-btn text v-on:click="signOut" v-if="hasUser">
      <span class="white--text text--lighten-1">Sign Out</span>
    </v-btn>
    <v-btn text small to="/signin" v-else>
      <span class="grey--text text--lighten-1"> Sign In</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { getCurrentUserUid } from "@/common/store";
import { signOut } from "@/main";

export default {
  name: "AppBarMenu",

  data: () => ({
    hasUser: false
  }),

  mounted() {
    this.setInitials();
  },

  methods: {
    setInitials() {
      this.hasUser = getCurrentUserUid();
      setTimeout(this.setInitials, 2000);
    },

    signOut() {
      signOut();
    }
  }
};
</script>
