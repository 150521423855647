export var IS_STAGING = false;

export function debugError(...args) {
  if (IS_STAGING) {
    // eslint-disable-next-line
    console.error(...args);
  }
}

export function debugLog(...args) {
  if (IS_STAGING) {
    // eslint-disable-next-line
    console.log(...args);
  }
}

export function setStaging(isStaging) {
  IS_STAGING = isStaging;
}
