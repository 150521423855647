<template>
  <v-container fluid class="main-container" style="text-align: center;">
    <PageHeader header="Upload Results" class=" mb-10" />

    <UserCode
      ref="userCodeComponent"
      @onInvalidUserCode="onInvalidUserCode"
      @onValidUserCode="onValidUserCode"
    />

    <UserInfo :code="userCode" @onData="onData" />

    <v-row class="mt-5" v-show="isUserCodeValid && serviceId">
      <v-col cols="6">
        <UploadSample
          header="CBC Results"
          :resultCode="DATA.CBC"
          :serviceId="serviceId"
          :userCode="userCode"
          @onSave="onSave"
        />
      </v-col>

      <v-col cols="6">
        <UploadSample
          header="ECG Results"
          :resultCode="DATA.ECG"
          :serviceId="serviceId"
          :userCode="userCode"
          @onSave="onSave"
        />
      </v-col>
    </v-row>

    <v-row class="mt-5" v-show="isUserCodeValid && serviceId">
      <v-col cols="6">
        <UploadSample
          header="Pap Smear Results"
          :resultCode="DATA.PAP_SMEAR"
          :serviceId="serviceId"
          :userCode="userCode"
          @onSave="onSave"
        />
      </v-col>

      <v-col cols="6">
        <UploadSample
          header="Xray Results"
          :resultCode="DATA.XRAY"
          :serviceId="serviceId"
          :userCode="userCode"
          @onSave="onSave"
        />
      </v-col>

      <v-col cols="6">
        <UploadSample
          header="Doctors Note Result"
          :resultCode="DATA.DOCTOR"
          :serviceId="serviceId"
          :userCode="userCode"
          @onSave="onSave"
        />
      </v-col>
    </v-row>

    <ErrorMessage :errorMessage="errorMessage" />

    <ConfirmDlg ref="confirm" />

    <SubmissionSummary
      :activeServiceId="serviceId"
      headerText1="Has CBC Data?"
      :headerValue1="DATA.CBC"
      headerText2="Has ECG Data?"
      :headerValue2="DATA.ECG"
      headerText3="Has Pap Smear Data?"
      :headerValue3="DATA.PAP_SMEAR"
      headerText4="Has Xray Data?"
      :headerValue4="DATA.XRAY"
      headerText5="Has DOCTOR Data?"
      :headerValue5="DATA.DOCTOR"
      :key="submissionKey"
    />
  </v-container>
</template>

<script>
import { DATA, STAGE, getActiveOffsiteService } from "@/store/offsiteService";

import { addData } from "../store";

import ConfirmDlg from "@/components/ConfirmDlg.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import PageHeader from "@/components/PageHeader.vue";
import SubmissionSummary from "@/components/SubmissionSummary4.vue";
import UploadSample from "../components/UploadSample.vue";
import UserCode from "@/components/UserCode.vue";
import UserInfo from "@/components/UserInfo.vue";

export default {
  name: "AddFecalysisResult",

  components: {
    ConfirmDlg,
    ErrorMessage,
    PageHeader,
    SubmissionSummary,
    UploadSample,
    UserCode,
    UserInfo
  },

  data: () => ({
    errorMessage: "",

    isUserCodeValid: false,
    userCode: "",

    serviceId: "",

    submissionKey: 0,

    DATA: DATA,
    STAGE: STAGE
  }),

  mounted() {
    const activeService = getActiveOffsiteService();
    this.serviceId = activeService ? activeService.id || "" : "";

    this.submissionKey++;
  },

  methods: {
    onSave(data) {
      addData(this.serviceId, this.userCode, data.resultCode, {
        filePath: data.fullPath
      }).then(() => {
        this.submissionKey++;
        this.$refs.confirm.open(
          "Confirmation",
          `${data.resultCode} upload completed`,
          {
            noconfirm: true
          }
        );
      });
    },

    onData(data) {
      this.errorMessage = "";
      this.userData = data;

      if (this.isUserCodeValid) {
        this.isSaved = false;
      } else {
        this.errorMessage = "Attendee cannot be found in the database";
      }
    },

    onInvalidUserCode(userCode) {
      this.userCode = userCode;
      this.isUserCodeValid = false;
    },

    onValidUserCode(userCode) {
      this.userCode = userCode;
      this.isUserCodeValid = true;
    }
  }
};
</script>

<style scoped>
.fit-in-row {
  padding-bottom: 0;
  padding-top: 0;
}

.text-field-style {
  margin: auto;
  max-width: 320px;
}
</style>
