<template>
  <div>
    <PageHeader header="Sample Submission" class=" mb-10" />

    <UserCode
      @onInvalidUserCode="onInvalidUserCode"
      @onValidUserCode="onValidUserCode"
    />

    <UserInfo :code="userCode" @onData="onData" />
    <p
      v-show="isValidUserCode && userCode"
      class="green--text mt-2 text-center"
    >
      <span v-show="urineSampleCollected">URINE SAMPLE COLLECTED</span>
      <span v-show="urineSampleCollected && fecalSampleCollected" class="mx-5">
        |
      </span>
      <span v-show="fecalSampleCollected">FECAL SAMPLE COLLECTED</span>
    </p>

    <div
      v-show="isValidUserCode"
      class="mt-10 text-center"
      style="margin: auto; max-width: 300px;"
    >
      <v-btn
        v-show="isValidUserCode && !urineSampleCollected"
        @click="saveContentUrine"
        class="mt-5"
        color="primary"
        :disabled="isButtonDisabled"
        style="margin: auto; max-width: 320px;"
      >
        Collect Urine Sample
      </v-btn>

      <v-btn
        v-show="isValidUserCode && !fecalSampleCollected"
        @click="saveContentFecal"
        class="mt-5"
        color="primary"
        :disabled="isButtonDisabled"
        style="margin: auto; max-width: 320px;"
      >
        Collect Fecal Sample
      </v-btn>
    </div>

    <ErrorMessage :errorMessage="errorMessage" />

    <ConfirmDlg ref="confirm" />

    <SubmissionSummary
      :activeServiceId="activeService.id"
      class="mt-15"
      :key="submissionKey"
    />
  </div>
</template>

<script>
import { HEADER, STAGE, getActiveOffsiteService } from "@/store/offsiteService";

import { addSubmission, getScheduleData } from "@/store/submissions";

import ConfirmDlg from "@/components/ConfirmDlg.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import PageHeader from "@/components/PageHeader.vue";
import SubmissionSummary from "../components/SubmissionSummary.vue";
import UserCode from "@/components/UserCode.vue";
import UserInfo from "@/components/UserInfo.vue";

export default {
  name: "AddData",

  components: {
    ConfirmDlg,
    ErrorMessage,
    PageHeader,
    SubmissionSummary,
    UserCode,
    UserInfo
  },

  data: () => ({
    activeService: { id: null, name: null },
    errorMessage: "",
    isSaving: false,
    isValidUserCode: false,
    submissionKey: 0,
    userCode: "",

    fecalSampleCollected: false,
    urineSampleCollected: false,

    HEADER: HEADER,
    STAGE: STAGE
  }),

  mounted() {
    this.activeService = getActiveOffsiteService();
    this.submissionKey++;
  },

  computed: {
    isButtonDisabled() {
      return this.isSaving;
    }
  },

  methods: {
    confirmSave() {
      if (
        this.$refs.confirm.open("Confirmation", "Save completed", {
          noconfirm: true
        })
      ) {
        this.submissionKey++;
        this.refreshData().finally(() => {
          this.isSaved = true;
          this.isSaving = false;
        });
      }
    },

    onData(data) {
      this.userData = data;

      if (this.isValidUserCode) {
        this.errorMessage = "Looking up user code. Please wait.";
        this.isSaved = false;

        if (data.name) {
          this.refreshData().finally(() => (this.errorMessage = ""));
        } else {
          this.errorMessage = "Attendee cannot be found in the database";
        }
      } else {
        this.errorMessage = "";
      }
    },

    onInvalidUserCode(userCode) {
      this.userCode = userCode;
      this.isValidUserCode = false;
    },

    onValidUserCode(userCode) {
      this.userCode = userCode;
      this.isValidUserCode = true;
    },

    refreshData() {
      return new Promise(resolve => {
        getScheduleData(this.activeService.id, this.userCode)
          .then(submissions => {
            this.fecalSampleCollected = submissions[STAGE.FECALYSIS];
            this.urineSampleCollected = submissions[STAGE.URINALYSIS];
            resolve();
          })
          .catch(err => {
            this.errorMessage = "getScheduleData Error: " + err;
          });
      });
    },

    saveContentFecal() {
      this.isSaving = true;

      addSubmission(
        STAGE.FECALYSIS,
        this.activeService.id,
        this.userCode
      ).then(() => this.confirmSave());
    },

    saveContentUrine() {
      this.isSaving = true;

      addSubmission(
        STAGE.URINALYSIS,
        this.activeService.id,
        this.userCode
      ).then(() => this.confirmSave());
    }
  }
};
</script>
