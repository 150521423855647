<template>
  <v-container fluid class="main-container" style="text-align: center;">
    <PageHeader
      header="Add Sample"
      :subheader="activeService.name || 'No service selected'"
      class=" mb-10"
    />

    <UserCode
      @onInvalidUserCode="onInvalidUserCode"
      @onValidUserCode="onValidUserCode"
    />

    <UserInfo :code="userCode" />
    <UserImage :code="userCode" :serviceId="activeService.id" />

    <v-row>
      <v-btn
        :disabled="!isValidUserCode || isUrinalysisSubmitted"
        @click="addUrinalysisSample"
        color="primary"
        style="margin: auto; max-width: 320px;"
      >
        Add Urinalysis Sample
      </v-btn>
    </v-row>

    <v-row class="mt-5">
      <v-btn
        :disabled="!isValidUserCode || isFecalysisSubmitted"
        @click="addFecalysisSample"
        color="primary"
        style="margin: auto; max-width: 320px;"
      >
        Add Fecalysis Sample
      </v-btn>
    </v-row>

    <ErrorMessage :errorMessage="errorMessage" />
  </v-container>
</template>

<script>
import { STAGE, getActiveOffsiteService } from "@/store/offsiteService";

import { addSubmission } from "@/store/submissions";
import { getFecalysis, getUrinalysis } from "../store";

import ErrorMessage from "@/components/ErrorMessage.vue";
import PageHeader from "@/components/PageHeader.vue";
import UserCode from "@/components/UserCode.vue";
import UserImage from "@/components/UserImage.vue";
import UserInfo from "@/components/UserInfo.vue";

export default {
  name: "AddSample",

  components: {
    ErrorMessage,
    PageHeader,
    UserCode,
    UserImage,
    UserInfo
  },

  data: () => ({
    activeService: { id: null, name: null },
    errorMessage: "",
    isFecalysisSubmitted: true,
    isUrinalysisSubmitted: true,
    isValidUserCode: false,
    userCode: ""
  }),

  mounted() {
    this.activeService = getActiveOffsiteService();
  },

  methods: {
    addFecalysisSample() {
      addSubmission(STAGE.FECALYSIS, this.activeService.id, this.userCode).then(
        () => (this.isFecalysisSubmitted = true)
      );
    },

    addUrinalysisSample() {
      addSubmission(
        STAGE.URINALYSIS,
        this.activeService.id,
        this.userCode
      ).then(() => (this.isUrinalysisSubmitted = true));
    },

    onInvalidUserCode(userCode) {
      this.userCode = userCode;
      this.isValidUserCode = false;
    },

    onValidUserCode(userCode) {
      this.userCode = userCode;
      this.isValidUserCode = true;

      getFecalysis(this.activeService.id, this.userCode)
        .then(result => {
          this.isFecalysisSubmitted = result.fecalysis ? true : false;
          return getUrinalysis(this.activeService.id, this.userCode);
        })
        .then(result => {
          this.isUrinalysisSubmitted = result.urinalysis ? true : false;
        })
        .catch(err => (this.errorMessage = "Get submissions error: " + err));
    }
  }
};
</script>
