<template>
  <v-row class="mt-10">
    <v-col class="errorStyle">
      {{ errorMessage }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ErrorMessage",

  props: {
    errorMessage: String
  }
};
</script>

<style scoped>
.errorStyle {
  color: red;
  text-align: center;
}
</style>
