<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card min-width="320" to="/setActiveService">
          <v-card-title>
            {{ activeService.id ? "Change" : "Set" }} Active Service
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="isActiveServiceToday">
        <v-card min-width="320" to="/addSample">
          <v-card-title>Add Sample</v-card-title>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="activeService.id">
        <v-card min-width="320" to="/viewSubmissions">
          <v-card-title>View Submissions</v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" class="text-center">
        <v-card-title class="justify-center this-header">
          Results
        </v-card-title>
      </v-col>

      <v-col cols="12">
        <v-card min-width="320" to="/addFecalysisResult">
          <v-card-title>Add Fecalysis Result</v-card-title>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card min-width="320" to="/addUrinalysisResult">
          <v-card-title>Add Urinalysis Result</v-card-title>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card min-width="320" to="/uploadResults">
          <v-card-title>Upload Results</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getActiveOffsiteService } from "@/store/offsiteService";
import { getDate } from "@/common/utilities/date";

export default {
  name: "MenuItems",

  mounted() {
    this.activeService = getActiveOffsiteService();
  },

  data: () => ({
    activeService: { id: null, name: null, schedule: null }
  }),

  computed: {
    isActiveServiceToday() {
      if (this.activeService.id) {
        return getDate(this.activeService.schedule) === getDate(new Date());
      }

      return false;
    }
  }
};
</script>
