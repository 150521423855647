import AddData from "./views/AddData.vue";
import AddFecalysisResult from "./views/AddFecalysisResult.vue";
import AddSample from "./views/AddSample.vue";
import AddUrinalysisResult from "./views/AddUrinalysisResult.vue";
import Menu from "./views/Menu.vue";
import UploadResults from "./views/UploadResults.vue";
import ViewSubmissions from "./views/ViewSubmissions.vue";

export function getRoutes(ROUTE, guard) {
  return [
    {
      path: ROUTE.MENU,
      name: "Menu",
      component: Menu,
      beforeEnter: guard
    },
    {
      path: ROUTE.ADD_DATA,
      name: "Add Samples",
      component: AddData,
      beforeEnter: guard
    },
    {
      path: "/addFecalysisResult",
      name: "AddFecalysisResult",
      component: AddFecalysisResult,
      beforeEnter: guard
    },
    {
      path: "/addSample",
      name: "AddSample",
      component: AddSample,
      beforeEnter: guard
    },
    {
      path: "/addUrinalysisResult",
      name: "AddUrinalysisResult",
      component: AddUrinalysisResult,
      beforeEnter: guard
    },
    {
      path: "/uploadResults",
      name: "Upload Results",
      component: UploadResults,
      beforeEnter: guard
    },
    {
      path: "/viewSubmissions",
      name: "View Submissions",
      component: ViewSubmissions,
      beforeEnter: guard
    }
  ];
}
