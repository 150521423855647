<template>
  <div>
    <v-btn color="primary" @click="$router.go(-1)">
      <v-icon>mdi-step-backward</v-icon> Back
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "BackButton"
};
</script>
