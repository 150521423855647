<template>
  <div class="text-center">
    <v-card-title class="justify-center mb-10">
      Select Video Input
    </v-card-title>

    <div v-for="(item, i) in videoInputs" :key="i">
      <v-btn @click="setActiveCamera(item.id)" :color="color(item.id)">
        {{ item.label }}
      </v-btn>
    </div>

    <ErrorMessage :errorMessage="errorMessage" />
  </div>
</template>

<script>
import { getActiveCamera, setActiveCamera } from "@/store/offsiteService";

import ErrorMessage from "@/components/ErrorMessage.vue";

export default {
  name: "SelectCamera",

  components: {
    ErrorMessage
  },

  data: () => ({
    activeCamera: "",
    errorMessage: "",
    videoInputs: []
  }),

  mounted() {
    this.activeCamera = getActiveCamera();

    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      this.errorMessage =
        "Cannot enumerate video sources. Please use another device.";
    } else {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          this.videoInputs = [];

          devices.forEach(device => {
            if ("videoinput" === device.kind) {
              this.videoInputs.push({
                label: device.label,
                id: device.deviceId
              });
            }
          });
        })
        .catch(err => {
          this.errorMessage = `${err.name}: ${err.message}`;
        });
    }
  },

  methods: {
    color(id) {
      return this.activeCamera && this.activeCamera === id ? "primary" : "gray";
    },

    setActiveCamera(id) {
      setActiveCamera(id);
      this.activeCamera = id;
    }
  }
};
</script>
