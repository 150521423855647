import Vue from "vue";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";

import { setDB } from "@/common/store";

Vue.config.productionTip = false;

firebase.initializeApp({
  apiKey: "AIzaSyD6lB8DibYrb5pR8K5P-2jC-05JNeGN-NI",
  authDomain: "medisure-275117.firebaseapp.com",
  databaseURL: "https://medisure-275117.firebaseio.com",
  projectId: "medisure-275117",
  storageBucket: "medisure-275117.appspot.com",
  messagingSenderId: "449387984097",
  appId: "1:449387984097:web:70e2cf37d24bc150be73a0",
  measurementId: "G-HWP7L6P2NQ"
});

export const db = firebase.firestore();
db.enablePersistence();

setDB(db);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

firebase.auth().onAuthStateChanged(user => {
  store.commit("setUser", user);

  if (!user && router.currentRoute.name !== "NotAllowed") {
    router.replace({ name: "Signin" });
  }
});

export function signOut() {
  firebase.auth().signOut();
}

export function reloadPageOnRoleValidation() {
  if (store.getters.isAllowed) {
    router.replace({ name: "Menu" });
  } else {
    signOut();
    store.commit("setUser", null);

    router.replace({ name: "NotAllowed" });
  }
}

export function signInEmail(email, password) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => resolve())
      .catch(error => reject(error));
  });
}
