import { padLeftZero } from "./number";

let moment = require("moment");

export function computeAge(targetDate, birthDate) {
  try {
    let age = targetDate.getFullYear() - birthDate.getFullYear();
    let m = targetDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && targetDate.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  } catch (err) {
    return -1;
  }
}

export function getAge(date) {
  return computeAge(new Date(), date);
}

export function getDateString(date) {
  let y = date.getFullYear().toString();
  let m = padLeftZero(date.getMonth() + 1);
  let d = padLeftZero(date.getDate());
  return `${y}-${m}-${d}`;
}

function toMoment(dateOrTimestamp) {
  return moment(
    dateOrTimestamp.seconds ? dateOrTimestamp.seconds * 1000 : dateOrTimestamp
  );
}

export function getVerboseDateStringFromTimestamp(timestamp) {
  return toMoment(timestamp).format("MMMM DD, YYYY");
}

export function getDateStringFromTimestamp(timestamp) {
  return toMoment(timestamp).format("YYYY-MM-DD");
}

export function getDateTime(timestamp = new Date()) {
  return toMoment(timestamp).format("MMMM D, YYYY h:mm A");
}

export function getDateTime1(timestamp = new Date()) {
  return toMoment(timestamp).format("MMM D, YYYY h:mm A");
}

export function getDateTimeFromDateAsKey(timestamp = new Date()) {
  return moment(timestamp).format("YYYY MM DD hhmmss");
}

export function getDateTimeFromDateAsKey1(timestamp = new Date()) {
  return moment(timestamp).format("YYYYMMDDhhmmss");
}

export function getDate(date) {
  return moment(new Date(date)).format("MMMM D, YYYY");
}

export function getDateFromTimestamp(timestamp) {
  return timestamp && timestamp.seconds
    ? moment(timestamp.seconds * 1000).format("MMMM D, YYYY")
    : "";
}

export function getDayDateTimeFromTimestamp(timestamp) {
  return moment(timestamp.seconds * 1000).format("llll");
}

export function getTime(timestamp = new Date()) {
  return toMoment(timestamp).format("h:mm A");
}

export function getTime24(timestamp = new Date()) {
  return toMoment(timestamp).format("HH:mm");
}

export function getToday() {
  return getVerboseDateStringFromTimestamp(new Date());
}

export function timeStampToDate(timestamp) {
  return new Date(timestamp.seconds * 1000);
}

export function yesterday(today) {
  return new Date(today.valueOf() - 1000 * 60 * 60 * 24);
}
