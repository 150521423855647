<template>
  <v-container fluid class="main-container" style="text-align: center;">
    <PageHeader header="Add Urinalysis Result" class=" mb-10" />

    <UserCode
      ref="userCodeComponent"
      @onInvalidUserCode="onInvalidUserCode"
      @onValidUserCode="onValidUserCode"
    />

    <UserInfo :code="userCode" @onData="onData" />

    <v-row class="mt-5" v-show="isUserCodeValid && serviceId">
      <v-col cols="12">
        Macroscopic
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Color"
          v-model="thisData.color"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Clarity"
          v-model="thisData.clarity"
          class="text-field-style"
        />
      </v-col>
    </v-row>

    <v-row class="mt-10" v-show="isUserCodeValid && serviceId">
      <v-col cols="12">
        Microscopic
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="WBC"
          v-model="thisData.wbc"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="RBC"
          v-model="thisData.rbc"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Epithelial Cells"
          v-model="thisData.epithelialCells"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Bacteria"
          v-model="thisData.bacteria"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Mucus Thread"
          v-model="thisData.mucusThread"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Amorphous Urates"
          v-model="thisData.amorphousUrates"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Amorphous Phosphate"
          v-model="thisData.amorphousPhosphate"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Yeast Cells"
          v-model="thisData.yeastCells"
          class="text-field-style"
        />
      </v-col>
    </v-row>

    <v-row class="mt-5" v-show="isUserCodeValid && serviceId">
      <v-col cols="12">
        Chemical Examination
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Urobilinogen"
          v-model="thisData.urobilinogen"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Bilirubin"
          v-model="thisData.bilirubin"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Protein"
          v-model="thisData.protein"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Blood"
          v-model="thisData.blood"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Ketone"
          v-model="thisData.ketone"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Nitrite"
          v-model="thisData.nitrite"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="WBC"
          v-model="thisData.wbcChemicalExamination"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Glucose"
          v-model="thisData.glucose"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="Specific Gravity"
          v-model="thisData.specificGravity"
          class="text-field-style"
        />
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row">
        <v-text-field
          label="pH"
          v-model="thisData.ph"
          class="text-field-style"
        />
      </v-col>
    </v-row>

    <v-row class="mt-5" v-show="isUserCodeValid && serviceId">
      <v-col cols="12">
        Remarks
      </v-col>

      <v-col md="6" cols="12" class="fit-in-row" style="margin: auto;">
        <v-textarea
          label="Remarks"
          v-model="thisData.remarks"
          style="max-width: 600px;"
        />
      </v-col>
    </v-row>

    <v-row class="mt-15" v-show="isUserCodeValid && serviceId">
      <v-btn
        :disabled="isSaveButtonDisabled"
        @click="addUrinalysisSample"
        color="primary"
        style="margin: auto; max-width: 320px;"
      >
        Save Urinalysis Result
      </v-btn>
    </v-row>

    <ErrorMessage :errorMessage="errorMessage" />

    <ConfirmDlg ref="confirm" />

    <SubmissionSummary
      :activeServiceId="serviceId"
      headerText1="Has Urine Sample?"
      :headerValue1="STAGE.URINALYSIS"
      headerText2="Has Urinalysis Data?"
      :headerValue2="DATA.URINALYSIS"
      :key="submissionKey"
    />
  </v-container>
</template>

<script>
import { DATA, STAGE, getActiveOffsiteService } from "@/store/offsiteService";

import {
  addUrinalysisData,
  getUrinalysisData,
  getUrinalysisDataFromResult
} from "../store";

import ConfirmDlg from "@/components/ConfirmDlg.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import PageHeader from "@/components/PageHeader.vue";
import SubmissionSummary from "@/components/SubmissionSummary2.vue";
import UserCode from "@/components/UserCode.vue";
import UserInfo from "@/components/UserInfo.vue";

export default {
  name: "AddUrinalysisResult",

  components: {
    ConfirmDlg,
    ErrorMessage,
    PageHeader,
    SubmissionSummary,
    UserCode,
    UserInfo
  },

  data: () => ({
    errorMessage: "",

    isUserCodeValid: false,
    userCode: "",

    serviceId: "",

    submissionKey: 0,

    thisData: {},
    lastData: {},

    DATA: DATA,
    STAGE: STAGE
  }),

  mounted() {
    const activeService = getActiveOffsiteService();
    this.serviceId =
      activeService && activeService.id ? activeService.id || "" : "";

    this.thisData = getUrinalysisDataFromResult(null);
    this.lastData = { ...this.thisData };
    this.submissionKey++;
  },

  methods: {
    addUrinalysisSample() {
      addUrinalysisData(this.serviceId, this.userCode, this.thisData).then(
        () => {
          this.submissionKey++;
          this.$refs.confirm.open(
            "Confirmation",
            "Urinalysis data upload completed",
            {
              noconfirm: true
            }
          );
        }
      );
    },

    onData(data) {
      this.userData = data;

      if (this.isUserCodeValid) {
        this.errorMessage = "Looking up user code. Please wait.";
        this.isSaved = false;

        if (data.name) {
          getUrinalysisData(this.serviceId, this.userCode)
            .then(result => {
              this.lastData = getUrinalysisDataFromResult(result);
              this.thisData = { ...this.lastData };
              this.errorMessage = "";
            })
            .catch(
              err => (this.errorMessage = `Get submissions error: ${err}`)
            );
        } else {
          this.errorMessage = "Attendee cannot be found in the database";
        }
      } else {
        this.errorMessage = "";
      }
    },

    onInvalidUserCode(userCode) {
      this.userCode = userCode;
      this.isUserCodeValid = false;
      this.thisData = getUrinalysisDataFromResult(null);
    },

    onValidUserCode(userCode) {
      this.userCode = userCode;
      this.isUserCodeValid = true;
    }
  },

  computed: {
    areChemicalExaminationDataUnchanged() {
      return (
        this.thisData.urobilinogen === this.lastData.urobilinogen &&
        this.thisData.bilirubin === this.lastData.bilirubin &&
        this.thisData.protein === this.lastData.protein &&
        this.thisData.blood === this.lastData.blood &&
        this.thisData.ketone === this.lastData.ketone &&
        this.thisData.nitrite === this.lastData.nitrite &&
        this.thisData.wbcChemicalExamination ===
          this.lastData.wbcChemicalExamination &&
        this.thisData.glucose === this.lastData.glucose &&
        this.thisData.specificGravity === this.lastData.specificGravity &&
        this.thisData.ph === this.lastData.ph
      );
    },

    areDataUnchanged() {
      return (
        this.areMacroscopicDataUnchanged &&
        this.areMicroscopicDataUnchanged &&
        this.areChemicalExaminationDataUnchanged &&
        this.thisData.remarks === this.lastData.remarks
      );
    },

    areMacroscopicDataUnchanged() {
      return (
        this.thisData.color === this.lastData.color &&
        this.thisData.clarity === this.lastData.clarity
      );
    },

    areMicroscopicDataUnchanged() {
      return (
        this.thisData.wbc === this.lastData.wbc &&
        this.thisData.rbc === this.lastData.rbc &&
        this.thisData.epithelialCells === this.lastData.epithelialCells &&
        this.thisData.bacteria === this.lastData.bacteria &&
        this.thisData.mucusThread === this.lastData.mucusThread &&
        this.thisData.amorphousUrates === this.lastData.amorphousUrates &&
        this.thisData.amorphousPhosphate === this.lastData.amorphousPhosphate &&
        this.thisData.yeastCells === this.lastData.yeastCells
      );
    },

    isChemicalExaminationDataIncomplete() {
      return (
        !this.thisData.urobilinogen ||
        !this.thisData.bilirubin ||
        !this.thisData.protein ||
        !this.thisData.blood ||
        !this.thisData.ketone ||
        !this.thisData.nitrite ||
        !this.thisData.wbcChemicalExamination ||
        !this.thisData.glucose ||
        !this.thisData.specificGravity ||
        !this.thisData.ph
      );
    },

    isDataIncomplete() {
      return (
        this.isMacroscopicDataDataIncomplete ||
        this.isMicroscopicDataDataIncomplete ||
        this.isChemicalExaminationDataIncomplete
      );
    },

    isMacroscopicDataDataIncomplete() {
      return !this.thisData.color || !this.thisData.clarity;
    },

    isMicroscopicDataDataIncomplete() {
      return (
        !this.thisData.wbc ||
        !this.thisData.rbc ||
        !this.thisData.epithelialCells ||
        !this.thisData.bacteria ||
        !this.thisData.mucusThread ||
        !this.thisData.amorphousUrates ||
        !this.thisData.amorphousPhosphate ||
        !this.thisData.yeastCells
      );
    },

    isSaveButtonDisabled() {
      return (
        !this.isUserCodeValid || this.isDataIncomplete || this.areDataUnchanged
      );
    }
  }
};
</script>

<style scoped>
.fit-in-row {
  padding-bottom: 0;
  padding-top: 0;
}

.text-field-style {
  margin: auto;
  max-width: 320px;
}
</style>
