<template>
  <v-row v-show="isPhotoTaken">
    <v-col class="text-center">
      <canvas ref="canvas" style="display:none;" />
      <img
        v-show="isPhotoTaken"
        alt="User Image"
        :src="imageSrc"
        style="max-width: 600px"
      />
    </v-col>
  </v-row>
</template>

<script>
import { getPhoto } from "@/Registration/store";

export default {
  name: "UserImage",

  props: {
    code: String,
    serviceId: String
  },

  data: () => ({
    errorMessage: "",
    imageSrc: "",
    isPhotoTaken: false
  }),

  watch: {
    code() {
      if (5 === this.code.length) {
        getPhoto(this.serviceId, this.code)
          .then(submissions => {
            if (submissions.photo) {
              this.imageSrc = submissions.photo;
              this.isPhotoTaken = true;
            } else {
              this.imageSrc = "";
              this.isPhotoTaken = false;
            }
          })
          .catch(err => (this.errorMessage = "getPhoto Error: " + err));
      } else {
        this.imageSrc = "";
        this.isPhotoTaken = false;
      }
    }
  }
};
</script>
