import { IS_STAGING, debugLog } from "../main";

import { getDateTimeFromDateAsKey } from "../utilities/date";

export var DB = null;
export var BASEDB = null;
var TRANSACTIONDB = null;

function initializeBaseDBNames() {
  BASEDB = {
    PROFILES: "profiles",
    BANTAY_KARD: "bantayKard",
    BANTAY_KARD_LOAD: "bantayKardLoad",
    MESSAGE_BOX: "messageBox",
    USER_VALIDATION: "userValidation",

    // companies
    COMPANIES: "companies",

    // diagnostics and clinic
    APPOINTMENTS: "appointments",
    DIAGNOSTIC_APPOINTMENTS: "diagnosticAppointments",
    DIAGNOSTIC_ATTENDEE: "diagnosticAttendee",
    DIAGNOSTIC_CLINICS: "diagnosticClinics",
    DIAGNOSTIC_SCHEDULES: "diagnosticSchedules",
    DIAGNOSTIC_PACKAGES: "diagnosticPackages",

    MEDICAL_HISTORY: "medicalhistory",
    TEST_SETTINGS: "testSettings",

    //doctor
    DOCTOR_SCHEDULES: "doctorSchedules",
    DOCTOR_CLINICS: "doctorClinics",
    DOCTOR_ATTENDEE: "diagnosticAttendee",
    DOCTOR_APPOINTMENTS: "doctorAppointments",

    // insurance
    INSURANCE_ACCOUNT: "insuranceAccount",
    INSURANCE_HISTORY: "insurancehistory",
    INSURANCE_PACKAGE: "insurancePackage",

    // lab results index
    LAB_RESULTS_INDEX: "labResultsIndex",

    // LGU services
    LGU_ATTENDEE: "lguAttendee",
    LGU_LOCATIONS: "lguLocations",
    LGU_SCHEDULES: "lguSchedules",
    LGU_SERVICES: "lguServices",
    LGU_VALIDATION: "lguValidation",

    // MOBILE VAN services
    MOBILE_ATTENDEE: "mobileAttendee",
    MOBILE_LOCATIONS: "mobileLocations",
    MOBILE_SCHEDULES: "mobileSchedules",
    MOBILE_SERVICES: "mobileServices",
    MOBILE_SUBMISSIONS: "mobileSubmissions",
    MOBILE_VALIDATION: "mobileValidation",

    // OFFSITE services
    OFFSITE_ATTENDEE: "offsiteAttendee",
    OFFSITE_LOCATIONS: "offsiteLocations",
    OFFSITE_RESULTS: "offsiteResults",
    OFFSITE_SCHEDULES: "offsiteSchedules",
    OFFSITE_SERVICES: "offsiteServices",
    OFFSITE_SUBMISSIONS: "offsiteSubmissions",
    OFFSITE_VALIDATION: "offsiteValidation",

    // store
    ADDTOCART: "addtocart",
    CATALOG: "catalog",
    PAYMENT: "payment",
    CHECKOUT: "checkout",
    DELIVERY: "delivery",
    DISPLAY: "display",
    PRODUCTS: "products",
    PRODUCTVARIANT: "productvariant",
    STORESETTINGS: "storesettings",
    PRODUCTIMAGES: "productimages",

    // botika
    BOTIKADISPLAY: "botikaDisplay",
    BOTIKASTORESETTINGS: "botikaStoresettings",
    BOTIKAPRODUCTIMAGES: "botikaProductimages",
    BOTIKAPRESCRIPTION: "botikaPrescription",

    CONTACTTRACING_INDEX: "contactTracingIndex",
    MEDICALHISTORY_INDEX: "medicalHistoryIndex",
    PHILHEALTH_INDEX: "philhealthIndex",

    // Bantay Kard
    BANTAYKARD_INDEX: "bantayKardIndex",

    // payment
    GCASH_RECEIPT: "gcashReceipt",

    SETTINGS: "settings",

    COVID_VACCINE: "covidVaccine",

    DISCOUNTS: "discounts"
  };

  Object.freeze(BASEDB);
}

function initializeTransactionDBNames(PREFIX) {
  const TRANSACTIONS = PREFIX + "transactions_";

  TRANSACTIONDB = {
    CATALOG: TRANSACTIONS + BASEDB.CATALOG,
    PAYMENT: TRANSACTIONS + BASEDB.PAYMENT,
    LGU_ATTENDEE: TRANSACTIONS + BASEDB.LGU_ATTENDEE,
    LGU_SCHEDULES: TRANSACTIONS + BASEDB.LGU_SCHEDULES,
    MOBILE_ATTENDEE: TRANSACTIONS + BASEDB.MOBILE_ATTENDEE,
    MOBILE_SCHEDULES: TRANSACTIONS + BASEDB.MOBILE_SCHEDULES,
    OFFSITE_ATTENDEE: TRANSACTIONS + BASEDB.OFFSITE_ATTENDEE,
    OFFSITE_SCHEDULES: TRANSACTIONS + BASEDB.OFFSITE_SCHEDULES,
    MESSAGE_BOX: TRANSACTIONS + BASEDB.MESSAGE_BOX,
    PRODUCTS: TRANSACTIONS + BASEDB.PRODUCTS,
    PROFILES: TRANSACTIONS + BASEDB.PROFILES,
    STORAGE: TRANSACTIONS + "storage",
    USER_SESSION: TRANSACTIONS + "userSession",

    COVID_VACCINE: TRANSACTIONS + BASEDB.COVID_VACCINE
  };

  Object.freeze(TRANSACTIONDB);
}

function initializeDBNamesWorker(PREFIX) {
  DB = {
    PROFILES: PREFIX + BASEDB.PROFILES,
    BANTAY_KARD: PREFIX + BASEDB.BANTAY_KARD,
    BANTAY_KARD_LOAD: PREFIX + BASEDB.BANTAY_KARD_LOAD,
    MESSAGE_BOX: PREFIX + BASEDB.MESSAGE_BOX,
    USER_VALIDATION: PREFIX + BASEDB.USER_VALIDATION,

    // companies
    COMPANIES: PREFIX + BASEDB.COMPANIES,

    // diagnostics and clinic
    APPOINTMENTS: PREFIX + BASEDB.APPOINTMENTS,
    DIAGNOSTIC_APPOINTMENTS: PREFIX + BASEDB.DIAGNOSTIC_APPOINTMENTS,
    DIAGNOSTIC_ATTENDEE: PREFIX + BASEDB.DIAGNOSTIC_ATTENDEE,
    DIAGNOSTIC_CLINICS: PREFIX + BASEDB.DIAGNOSTIC_CLINICS,
    DIAGNOSTIC_SCHEDULES: PREFIX + BASEDB.DIAGNOSTIC_SCHEDULES,
    DIAGNOSTIC_PACKAGES: PREFIX + BASEDB.DIAGNOSTIC_PACKAGES,
    MEDICAL_HISTORY: PREFIX + BASEDB.MEDICAL_HISTORY,
    TEST_SETTINGS: PREFIX + BASEDB.TEST_SETTINGS,

    //doctor
    DOCTOR_SCHEDULES: PREFIX + BASEDB.DOCTOR_SCHEDULES,
    DOCTOR_CLINICS: PREFIX + BASEDB.DOCTOR_CLINICS,
    DOCTOR_ATTENDEE: PREFIX + BASEDB.DOCTOR_ATTENDEE,
    DOCTOR_APPOINTMENTS: PREFIX + BASEDB.DOCTOR_APPOINTMENTS,

    // insurance
    INSURANCE_ACCOUNT: PREFIX + BASEDB.INSURANCE_ACCOUNT,
    INSURANCE_HISTORY: PREFIX + BASEDB.INSURANCE_HISTORY,
    INSURANCE_PACKAGE: PREFIX + BASEDB.INSURANCE_PACKAGE,

    // lab results index
    LAB_RESULTS_INDEX: PREFIX + BASEDB.LAB_RESULTS_INDEX,

    // LGU services
    LGU_ATTENDEE: PREFIX + BASEDB.LGU_ATTENDEE,
    LGU_LOCATIONS: PREFIX + BASEDB.LGU_LOCATIONS,
    LGU_SCHEDULES: PREFIX + BASEDB.LGU_SCHEDULES,
    LGU_SERVICES: PREFIX + BASEDB.LGU_SERVICES,
    LGU_VALIDATION: PREFIX + BASEDB.LGU_VALIDATION,

    // MOBILE VAN services
    MOBILE_ATTENDEE: PREFIX + BASEDB.MOBILE_ATTENDEE,
    MOBILE_LOCATIONS: PREFIX + BASEDB.MOBILE_LOCATIONS,
    MOBILE_SCHEDULES: PREFIX + BASEDB.MOBILE_SCHEDULES,
    MOBILE_SERVICES: PREFIX + BASEDB.MOBILE_SERVICES,
    MOBILE_SUBMISSIONS: PREFIX + BASEDB.MOBILE_SUBMISSIONS,
    MOBILE_VALIDATION: PREFIX + BASEDB.MOBILE_VALIDATION,

    // OFFSITE services
    OFFSITE_ATTENDEE: PREFIX + BASEDB.OFFSITE_ATTENDEE,
    OFFSITE_LOCATIONS: PREFIX + BASEDB.OFFSITE_LOCATIONS,
    OFFSITE_RESULTS: PREFIX + BASEDB.OFFSITE_RESULTS,
    OFFSITE_SCHEDULES: PREFIX + BASEDB.OFFSITE_SCHEDULES,
    OFFSITE_SERVICES: PREFIX + BASEDB.OFFSITE_SERVICES,
    OFFSITE_SUBMISSIONS: PREFIX + BASEDB.OFFSITE_SUBMISSIONS,
    OFFSITE_VALIDATION: PREFIX + BASEDB.OFFSITE_VALIDATION,

    // store
    ADDTOCART: PREFIX + BASEDB.ADDTOCART,
    CATALOG: PREFIX + BASEDB.CATALOG,
    PAYMENT: PREFIX + BASEDB.PAYMENT,
    CHECKOUT: PREFIX + BASEDB.CHECKOUT,
    DELIVERY: PREFIX + BASEDB.DELIVERY,
    DISPLAY: PREFIX + BASEDB.DISPLAY,
    PRODUCTS: PREFIX + BASEDB.PRODUCTS,
    PRODUCTVARIANT: PREFIX + BASEDB.PRODUCTVARIANT,
    STORESETTINGS: PREFIX + BASEDB.STORESETTINGS,
    PRODUCTIMAGES: PREFIX + BASEDB.PRODUCTIMAGES,

    // botika
    BOTIKADISPLAY: PREFIX + BASEDB.BOTIKADISPLAY,
    BOTIKASTORESETTINGS: PREFIX + BASEDB.BOTIKASTORESETTINGS,
    BOTIKAPRODUCTIMAGES: PREFIX + BASEDB.BOTIKAPRODUCTIMAGES,
    BOTIKAPRESCRIPTION: PREFIX + BASEDB.BOTIKAPRESCRIPTION,

    CONTACTTRACING_INDEX: PREFIX + BASEDB.CONTACTTRACING_INDEX,
    MEDICALHISTORY_INDEX: PREFIX + BASEDB.MEDICALHISTORY_INDEX,
    PHILHEALTH_INDEX: PREFIX + BASEDB.PHILHEALTH_INDEX,

    // Bantay Kard
    BANTAYKARD_INDEX: PREFIX + BASEDB.BANTAYKARD_INDEX,

    // payment
    GCASH_RECEIPT: PREFIX + BASEDB.GCASH_RECEIPT,

    SETTINGS: PREFIX + BASEDB.SETTINGS,

    COVID_VACCINE: PREFIX + BASEDB.COVID_VACCINE,
    DISCOUNTS: PREFIX + BASEDB.DISCOUNTS
  };

  Object.freeze(DB);
}

export function initializeDBNames() {
  const PREFIX = IS_STAGING ? "test_" : "";

  initializeBaseDBNames();
  initializeTransactionDBNames(PREFIX);
  initializeDBNamesWorker(PREFIX);
}

const CURRENT_SESSION_ID = "medisureSessionId";
const CURRENT_USER = "medisureCurrentUser";
const CURRENT_USER_UID = "medisureCurrentUserUid";
const CURRENT_USER_EMAIL = "medisureCurrentUserEmail";

function getValue(key) {
  const item = window.sessionStorage.getItem(key);
  return "null" === item ? "" : item;
}

export function getCurrentSessionId() {
  return getValue(CURRENT_SESSION_ID);
}

export function getCurrentUser() {
  return getValue(CURRENT_USER) || "Not specified";
}

export function getCurrentUserEmail() {
  return getValue(CURRENT_USER_EMAIL);
}

export function getCurrentUserUid() {
  return getValue(CURRENT_USER_UID);
}

function setValueOrRemove(key, value) {
  if (value) {
    window.sessionStorage.setItem(key, value);
  } else {
    window.sessionStorage.removeItem(key);
  }
}

export function setCurrentSessionId(value = "") {
  return setValueOrRemove(CURRENT_SESSION_ID, value);
}

export function setCurrentUserEmail(value = "") {
  return setValueOrRemove(CURRENT_USER_EMAIL, value);
}

export function setCurrentUserName(value = "") {
  return setValueOrRemove(CURRENT_USER, value);
}

export function setCurrentUserUid(value = "") {
  return setValueOrRemove(CURRENT_USER_UID, value);
}

function logUserLog(operation) {
  return new Promise(resolve => {
    let currentSessionId = getCurrentSessionId();

    if (currentSessionId) {
      db.collection(TRANSACTIONDB.USER_SESSION)
        .doc(currentSessionId)
        .set(
          {
            [getDateTimeFromDateAsKey()]: {
              operation: operation,
              uid: getCurrentUserUid()
            }
          },
          { merge: true }
        )
        .then(() => resolve())
        .catch(() => resolve());
    } else {
      resolve();
    }
  });
}

export function getPaymentTransaction() {
  return new Promise((resolve, reject) => {
    db.collection(TRANSACTIONDB.PAYMENT)
      .where("initiatedBy", "==", getCurrentUserUid())
      .get()
      .then(snapshot => {
        let dis = [];
        snapshot.forEach(doc => {
          dis.push({
            id: doc.id,
            data: doc.data()
          });
        });
        resolve(dis);
      })
      .catch(err => reject(err));
  });
}

export function setCurrentUser(thisCurrentUserUid = "", thisCurrentUser = "") {
  if (thisCurrentUserUid) {
    setCurrentUserName(thisCurrentUser);
    setCurrentUserUid(thisCurrentUserUid);

    db.collection(TRANSACTIONDB.USER_SESSION)
      .add({
        [getDateTimeFromDateAsKey()]: {
          operation: "Logged in",
          uid: thisCurrentUserUid
        }
      })
      .then(doc => {
        setCurrentSessionId(doc.id);
        logUserLog("Logged in");
      });
  } else {
    setCurrentUserName();
    setCurrentUserUid();

    if (getCurrentSessionId()) {
      logUserLog("Logged out").then(() => setCurrentSessionId());
    }
  }
}

var db = null;
export function setDB(thisDb) {
  db = thisDb;
}

function logTransaction(collectionName, timestamp, operation, newValues) {
  return new Promise(resolve => {
    db.collection(collectionName)
      .add({
        timestamp: timestamp,
        initiatedBy: getCurrentUserUid(),
        operation: operation,
        newValues: { ...newValues }
      })
      .then(doc => {
        debugLog(`Added to ${collectionName} - ${doc.id}`);
        resolve();
      })
      .catch(() => resolve());
  });
}

function logBatchTransactionEntry(collectionName, timestamp, operation, id) {
  return new Promise(resolve => {
    db.collection(collectionName)
      .add({
        timestamp: timestamp,
        initiatedBy: getCurrentUserUid(),
        operation: `queued for batch: ${operation}`,
        id: id
      })
      .then(doc => {
        debugLog(`Added to ${collectionName} - ${doc.id}`);
        resolve();
      })
      .catch(() => resolve());
  });
}

//------------------------ LGU ATTENDEE TRANSACTIONS ------------------------//

export function logLguAttendeeBatchTransactionEntry(timestamp, operation, id) {
  return logBatchTransactionEntry(
    TRANSACTIONDB.LGU_ATTENDEE,
    timestamp,
    operation,
    id
  );
}

//------------------------ LGU SCHEDULES TRANSACTIONS ------------------------//

export function logLguSchedulesBatchTransactionEntry(timestamp, operation, id) {
  return logBatchTransactionEntry(
    TRANSACTIONDB.LGU_SCHEDULES,
    timestamp,
    operation,
    id
  );
}

export function logLguSchedulesTransaction(timestamp, operation, newValues) {
  return logTransaction(
    TRANSACTIONDB.LGU_SCHEDULES,
    timestamp,
    operation,
    newValues
  );
}

//--------------------------- CATALOG TRANSACTIONS ---------------------------//

export function logCatalogTransaction(timestamp, operation, newValues) {
  return logTransaction(TRANSACTIONDB.CATALOG, timestamp, operation, newValues);
}

function addTransaction(
  transactionName,
  doc,
  timestamp,
  operation,
  targetUid,
  newValues
) {
  return new Promise(resolve => {
    doc
      .set({
        timestamp: timestamp,
        initiatedBy: getCurrentUserUid(),
        operation: operation,
        uid: targetUid,

        newValues: { ...newValues }
      })
      .then(() => {
        debugLog("Added to", transactionName, "transaction", doc.id);
        resolve();
      });
  });
}

//------------------------ COVID VACCINE TRANSACTIONS ------------------------//

export function logCovidVaccineTransaction(timestamp, operation, newValues) {
  return logTransaction(
    TRANSACTIONDB.COVID_VACCINE,
    timestamp,
    operation,
    newValues
  );
}

//------------------------- MESSAGE BOX TRANSACTIONS -------------------------//

export function logMessageBoxTransaction(
  timestamp,
  operation,
  messageUid,
  newValues
) {
  let docData = db.collection(TRANSACTIONDB.MESSAGE_BOX).doc();
  return addTransaction(
    "diagnostic",
    docData,
    timestamp,
    operation,
    messageUid,
    newValues
  );
}

//--------------------------- PRODUCT TRANSACTIONS ---------------------------//

export function logProductTransaction(
  timestamp,
  operation,
  productDocUid,
  newValues
) {
  let docData = db.collection(TRANSACTIONDB.PRODUCTS).doc();
  return addTransaction(
    "product",
    docData,
    timestamp,
    operation,
    productDocUid,
    newValues
  );
}

export function logPaymentTransaction(timestamp, operation, newValues) {
  return logTransaction(TRANSACTIONDB.PAYMENT, timestamp, operation, newValues);
}

//-------------------------- PROFILES TRANSACTIONS --------------------------//

export function logProfilesTransaction(timestamp, operation, newValues) {
  return logTransaction(
    TRANSACTIONDB.PROFILES,
    timestamp,
    operation,
    newValues
  );
}

//--------------------------- STORAGE TRANSACTIONS ---------------------------//

export function logStorageTransaction(timestamp, operation, data) {
  return new Promise(resolve => {
    db.collection(TRANSACTIONDB.STORAGE)
      .add({
        ...data,

        timestamp: timestamp,
        initiatedBy: getCurrentUserUid(),
        operation: operation
      })
      .then(doc => {
        debugLog("Added to storage transaction", doc.id);
        resolve();
      })
      .catch(() => resolve());
  });
}

export function logUploadFailedTransaction(timestamp, path) {
  return logStorageTransaction(timestamp, "upload failed", { path: path });
}

export function logUploadSucceededTransaction(timestamp, path, downloadUrl) {
  return logStorageTransaction(timestamp, "upload succeeded", {
    path: path,
    downloadUrl: downloadUrl
  });
}

//-------------------------- STORESETTINGS ACTIONS --------------------------//

export function loadProductCategories(isBotika) {
  return new Promise((resolve, reject) => {
    db.collection(DB.STORESETTINGS)
      .doc(isBotika ? "botikacategories" : "categories")
      .get()
      .then(doc => {
        let categories = [];
        let data = doc.data();

        if (data && data.categories) {
          categories = [...data.categories];
        }

        resolve(categories);
      })
      .catch(err => reject(err));
  });
}

//---------------------- OFFSITE ATTENDEE TRANSACTIONS ----------------------//

export function logOffsiteAttendeeBatchTransactionEntry(
  timestamp,
  operation,
  id
) {
  return logBatchTransactionEntry(
    TRANSACTIONDB.OFFSITE_ATTENDEE,
    timestamp,
    operation,
    id
  );
}

//---------------------- OFFSITE SCHEDULES TRANSACTIONS ----------------------//

export function logOffsiteSchedulesBatchTransactionEntry(
  timestamp,
  operation,
  id
) {
  return logBatchTransactionEntry(
    TRANSACTIONDB.OFFSITE_SCHEDULES,
    timestamp,
    operation,
    id
  );
}

export function logOffsiteSchedulesTransaction(
  timestamp,
  operation,
  newValues
) {
  return logTransaction(
    TRANSACTIONDB.OFFSITE_SCHEDULES,
    timestamp,
    operation,
    newValues
  );
}

//-------------------------- USER ACTIVITY LOGGING --------------------------//

export function logUserActivity(operation, newValues) {
  return new Promise(resolve => {
    let currentSessionId = getCurrentSessionId();

    if (currentSessionId) {
      db.collection(TRANSACTIONDB.USER_SESSION)
        .doc(currentSessionId)
        .set(
          {
            [getDateTimeFromDateAsKey()]: {
              operation: operation,
              uid: getCurrentUserUid(),
              newValues: { ...newValues }
            }
          },
          { merge: true }
        )
        .then(() => resolve())
        .catch(() => resolve());
    } else {
      resolve();
    }
  });
}

//-------------------------- PREBOOKING --------------------------//

export function getPrebookingCount() {
  return new Promise((resolve, reject) => {
    db.collection(DB.COVID_VACCINE)
      .get()
      .then(querySnapshot => resolve(querySnapshot.size))
      .catch(err => reject(err));
  });
}

export function getPrebookingAttendee() {
  return new Promise((resolve, reject) => {
    db.collection(DB.COVID_VACCINE)
      .get()
      .then(snapshot => {
        let attendee = [];
        snapshot.forEach(doc => {
          attendee.push({
            id: doc.id,
            data: doc.data()
          });
        });
        resolve(attendee);
      })
      .catch(err => reject(err));
  });
}

function getAttendees(key, value) {
  return new Promise((resolve, reject) => {
    db.collection(DB.COVID_VACCINE)
      .where(key, "==", value)
      .get()
      .then(snapshot => {
        let attendees = [];

        snapshot.forEach(doc => {
          attendees.push({
            id: doc.id,
            data: doc.data()
          });
        });

        resolve(attendees);
      })
      .catch(err => reject(err));
  });
}

export function getPrebookingAttendeebyMunicipality(municipality) {
  return getAttendees("location.municipality", municipality.city);
}

export function getPrebookingAttendeebyBarangay(barangay) {
  return getAttendees("location.barangay", barangay.barangay);
}

export function dbGetLocations(locations) {
  return db.collection(locations).get();
}

function loadData(docId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.STORESETTINGS)
      .doc(docId)
      .get()
      .then(doc => resolve(doc.exists ? doc.data() : {}))
      .catch(error => reject(error));
  });
}

export function loadMerchants() {
  return loadData("merchants");
}

export function loadShippingPromo() {
  return loadData("shippingPromo");
}
