<template>
  <v-text-field
    label="User Code"
    v-model="userCode"
    @keyup="uppercaseUserCode"
    style="margin: auto; max-width: 320px;"
  />
</template>

<script>
import { verifyUserCode } from "@/common/utilities/user_code";

export default {
  name: "UserCode",

  data: () => ({
    userCode: ""
  }),

  methods: {
    clearUserCode() {
      this.userCode = "";
    },

    uppercaseUserCode() {
      this.userCode = this.userCode.toUpperCase();

      if (verifyUserCode(this.userCode)) {
        this.$emit("onValidUserCode", this.userCode);
      } else {
        this.$emit("onInvalidUserCode", this.userCode);
      }
    }
  }
};
</script>
